import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataPropertyInfoService, SharedTermsTranslationKey } from '@unifii/library/common';
import { ColumnDescriptor, PublishedContent, StructureNode, StructureNodeType, Table, TableDetailTemplate, TableSourceType, UfRequestError, VisibleFilterDescriptor, ensureUfError } from '@unifii/sdk';

import { Config } from 'config';
import { AppError } from 'shell/errors/errors';
import { NavigationService } from 'shell/nav/navigation.service';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { TableNodeInfo } from 'shell/shell-model';

const fade = trigger('fade', [
	transition(':leave', [
		animate('400ms ease', style({ opacity: 0 })),
	]),
]);

@Component({
	selector: 'us-dashboard-page',
	templateUrl: './dashboard-page.html',
	styleUrls: ['../content/content-node.less'],
	animations: [fade],
})
export class DashboardPageComponent implements OnInit {

    protected readonly sharedTk = SharedTermsTranslationKey;
    protected isMenuView: boolean;
    protected error: AppError | undefined;
    protected tableNodeInfos: TableNodeInfo[];
    protected pageMode = TableDetailTemplate.PageView;
    protected emptyMessage: string | undefined;

    private config = inject(Config);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private nav = inject(NavigationService);
    private content = inject(PublishedContent);
    private dataPropertyInfoService = inject(DataPropertyInfoService);

    async ngOnInit() {
        const dashboardNode = this.nav.current as unknown as StructureNode;

        this.isMenuView = !!dashboardNode.tags?.includes(TableDetailTemplate.MenuView);
        this.pageMode = dashboardNode.tags?.includes(TableDetailTemplate.PageViewHideEmptyTables) ?
            TableDetailTemplate.PageViewHideEmptyTables :
            TableDetailTemplate.PageView;
        this.emptyMessage = dashboardNode.emptyMessage;

        try {
            this.tableNodeInfos = await this.getTablesNodeInfo(dashboardNode);
        } catch (error) {
            this.error = ensureUfError(error);

            return;
        }

        const firstTableInfo = this.tableNodeInfos[0];

        if (!firstTableInfo) {
            this.error = new UfRequestError('your data is empty');

            return;
        }

        const childIdentifier = this.route.children[0]?.snapshot.params.identifier;

        if (this.isMenuView && !childIdentifier) {
            void this.router.navigate([firstTableInfo.table.identifier], { relativeTo: this.route });
        }
    }

    private async getTablesNodeInfo(node: StructureNode): Promise<TableNodeInfo[]> {
        const items: TableNodeInfo[] = [];

        for (const { identifier, nodeId, pageSize } of node.bucketOptions ?? []) {

            if (identifier) {
                if (!PermissionsFunctions.getTablePath(this.config.unifii.projectId, identifier).length) {
                    continue;
                }

                const table = await this.content.getTable(identifier);

                if (table.sourceType !== TableSourceType.Bucket) {
                    continue;
                }

                items.push({ table, pageSize });
            } else if (nodeId) {
                const tableInfo = await this.getTableFromStructureNode(nodeId, pageSize);

                if (tableInfo) {
                    items.push(tableInfo);
                }
            }
        }

        return items;
    }

    /**
     * @deprecated
     * This method is to make sure old implementations of dashboard keep working
     */
    private async getTableFromStructureNode(nodeId: string, pageSize: number): Promise<TableNodeInfo | undefined> {
        const bucketNode = this.nav.findNode((n) => n.type === StructureNodeType.FormBucket && n.nodeId === nodeId);

        if (!bucketNode) {
            return;
        }

        if (!this.nav.canAccessNode(bucketNode)) {
            return;
        }

        const table = await this.getTable(bucketNode);

        if (!table || table.sourceType !== TableSourceType.Bucket) {
            return;
        }

        return { table, pageSize };
    }

    private getTable(node: StructureNode): Promise<Table | undefined> {

        // Used to keep the Table id as configuration of the Form Data
        if (node.id && node.definitionIdentifier) {
            if (!PermissionsFunctions.getTablePath(this.config.unifii.projectId, node.definitionIdentifier).length) {
                return Promise.resolve(undefined);
            }

            return this.content.getTable(node.definitionIdentifier);
        }

        // Map ColumDescriptor array with with retro-compatibility
        const columns = (node.columns ?? Object.keys(this.dataPropertyInfoService.formDefinitionReferences))
            .map((i) => ({ identifier: i } as ColumnDescriptor));

        const visibleFilters: VisibleFilterDescriptor[] | undefined = node.userFilterOptions;

        return Promise.resolve({
            title: node.name as any as string,
            identifier: null as any as string,
            name: node.name,
            sourceType: TableSourceType.Bucket,
            source: node.definitionIdentifier,
            columns,
            defaultSort: node.defaultSort,
            visibleFilters,
            filter: node.filter,
        });
    }

}
