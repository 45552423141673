import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey } from '@unifii/library/common';
import { AuthProvider, Dictionary, decrypt } from '@unifii/sdk';

import { Config, Environment } from 'config';
import { ProjectSelectionPath, UserAccessRootPath } from 'discover/discover-constants';
import { Authentication } from 'shell/services/authentication';
import { Auth0DirectoryURL, AzureDirectoryURL, SSOService } from 'shell/services/sso.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

@Component({
    templateUrl: './sso.html',
})
export class SSOComponent {

    constructor(
        @Inject(Environment) private env: Config,
        private router: Router,
        private route: ActivatedRoute,
        @Inject(Authentication) private auth: Authentication,
        private translate: TranslateService,
        private userAccessManager: UserAccessManager,
        private ssoService: SSOService,
    ) {
        this.init(this.route.snapshot.queryParams);
    }

    private async init({ state, code, reason, provider_id }: Dictionary<string>) {

        try {
            let redirectUri;

            if (reason) {
                throw new Error(reason);
            }

            if (state) {
                const decryptedState = await this.decryptState(state);

                provider_id = decryptedState.providerId;
                redirectUri = decryptedState.redirectUri;
            }

            if (!redirectUri) {
                redirectUri = this.getRedirectUri(provider_id);
            }

            await this.auth.login({ authCode: code, redirectUri, providerId: provider_id });

            // Redirect on successful login
            this.router.navigate([`/${UserAccessRootPath}`, ProjectSelectionPath]);

        } catch (e) {
            const message = (e as Error).message || this.getProviderErrorMessage(provider_id);

            this.userAccessManager.deny({ error: message });
        }
    }

    private getProviderErrorMessage(providerId: string | undefined): string {

        const provider = providerId ? this.ssoService.getProvider(providerId) : undefined;
        const type = provider?.type;

        switch (type) {
            case AuthProvider.Azure: return this.translate.instant(CommonTranslationKey.SsoErrorAzureAuthenticationFailedMessage);
            case AuthProvider.Auth0: return this.translate.instant(CommonTranslationKey.SsoErrorAuth0AuthenticationFailedMessage);
            default: return this.translate.instant(CommonTranslationKey.SsoErrorAuthenticaionFailedMessage);
        }
    }

    private async decryptState(state: string): Promise<{ redirectUri: string; providerId: string }> {

        const decrypted = await decrypt({ byteString: decodeURIComponent(state), key: this.env.unifii.appId as string });
        const params = new URLSearchParams(decodeURIComponent(decrypted));

        return {
            providerId: params.get('providerId') as string,
            redirectUri: params.get('redirectUri') as string,
        };
    }

    private getRedirectUri(providerId: string | undefined): string {

        const provider = providerId ? this.ssoService.getProvider(providerId) : undefined;

        if (provider?.useDirectory === false) {
            return this.ssoService.loginRedirectUri;
        }

        switch (provider?.type) {
            case AuthProvider.Azure: return AzureDirectoryURL;
            case AuthProvider.Auth0: return Auth0DirectoryURL;
            default: throw new Error(this.translate.instant(CommonTranslationKey.SsoErrorAuthenticaionFailedMessage));
        }
    }

}
