import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OptionsModalComponent } from '@unifii/components';
import { CellDisplayDescriptor, Context, ContextProvider, ExpressionParser, HierarchyUnitProvider, ModalService, SharedTermsTranslationKey, SortStatus, TableComponent, TableConfig, TableDataSource } from '@unifii/library/common';
import { AstNode, Client, CompaniesClient, Dictionary, Option, PermissionAction, Table, TableDetailTemplate, TableSourceType, UsersClient, ensureUfRequestError, hasLengthAtLeast } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { Config } from 'config';
import { TableDisplayMode } from 'shell/content/content-node.component';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { ShellFormService } from 'shell/form/shell-form.service';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { FormDataPath, NewItemPath } from 'shell/shell-constants';
import { ShellTranslationKey } from 'shell/shell.tk';
import { CompanyTableDataSource } from 'shell/table/companies/company-table-datasource';
import { BucketTableDataSource } from 'shell/table/form-data/bucket-table-datasource';
import { TableData } from 'shell/table/models';
import { TableColumnFactory } from 'shell/table/table-column-factory';
import { checkShowCount, getTableCustomColumnsDisplayDescriptors } from 'shell/table/table-functions';
import { TableInputManagerFactory } from 'shell/table/table-input-manager-factory';
import { UsersTableDataSource } from 'shell/table/users/users-table-datasource';
import { TableDetailContextProvider } from 'shell/table-detail/table-detail-context-provider';

import { TableModule } from './table-detail.component';

@Component({
    selector: 'us-table-module',
    templateUrl: './table-module.html',
    styleUrls: ['../dashboard/dashboard-table.less'],
    providers: [ShellFormService],
})
export class TableModuleComponent implements OnInit, OnDestroy {

    @ViewChild(TableComponent) tableComponent: TableComponent<TableData> | null;

    @Input() module: TableModule;
    @Input() item: TableData;
    @Input() detailContextProvider: TableDetailContextProvider;
    @Input() pageMode = TableDetailTemplate.PageView;
    @Output() hideChange = new EventEmitter<boolean>();

    @HostBinding('class.hide') protected _hide: boolean;

    protected readonly sharedTK = SharedTermsTranslationKey;
    protected readonly shellTK = ShellTranslationKey;
    protected title: string;
    protected tableConfig: TableConfig<any>;
    protected customColumns: CellDisplayDescriptor[] = [];
    protected tableLink: any[];
    protected error: AppError | undefined;
    protected dataSource?: TableDataSource<TableData>;
    protected recordCount?: number;
    protected showCount: boolean;

    private usersClient = inject(UsersClient);
    private expParser = inject(ExpressionParser);
    private formService = inject(ShellFormService);
    private config = inject(Config);
    private auth = inject(Authentication);
    private contextProvider = inject(ContextProvider);
    private hierarchyUnitProvider = inject(HierarchyUnitProvider);
    private router = inject(Router);
    private client = inject(Client);
    private errorService = inject(ErrorService);
    private modalService = inject(ModalService);
    private translate = inject(TranslateService);
    private tableColumnFactory = inject(TableColumnFactory);
    private dataSubscription: Subscription | null;

    get showSeeMoreRow() {
        return this.tableComponent?.status.exhausted === false;
    }

    private set hide(v: boolean) {
        if (v === this._hide) {
            return;
        }
        this._hide = v;
        this.hideChange.emit(v);
    }

    ngOnInit() {

        const { detailModule, pageConfig } = this.module;

        try {
            // Guard for non matching roles with those requested by the TableModule
            if (detailModule.roles?.length && !detailModule.roles.some((r) => this.auth.userInfo?.roles?.includes(r))) {
                return;
            }

            const { table, propertyDescriptors } = pageConfig;

            this.dataSource = this.createDataSource(table, detailModule.filter);

            if (detailModule.title) {
                this.title = detailModule.title;
            } else {
                this.title = table.title;
            }

            this.customColumns = getTableCustomColumnsDisplayDescriptors(propertyDescriptors, table.columns);

            this.tableConfig = {
                columns: this.tableColumnFactory.create(table.columns ?? [], propertyDescriptors, table.sourceType, false),
                pageSize: detailModule.limit ?? 5,
                rowAction: (item: TableData) => {
                    if (this.canRouteToItem(item, table.sourceType, table.source)) {
                        this.routeToItem(detailModule.identifier, item.id as string, table);
                    }
                },
            };

            this.tableLink = [table.identifier];

            void this.loadPageCount();

        } catch (e) {
            console.error('TableModuleComponent.ngOnInit error: ', e);
            this.error = ensureUfRequestError(e, this.errorService.unknownErrorMessage);
        }
    }

    ngOnDestroy() {
        this.dataSubscription?.unsubscribe();
    }

    /** Based on TableModule.canAdd and TableModule.filter */
    async addLinked() {

        const { moduleConfig, pageConfig } = this.module;

        if (!moduleConfig?.addOptions || !pageConfig.bucket || !moduleConfig.filterLink) {
            return;
        }

        const expressionValue = this.expParser.resolve(
            moduleConfig.filterLink.expression,
            this.detailContextProvider.get() as Context,
            undefined,
            `TableDetailModule: failed to parse ${moduleConfig.filterLink.expression}`,
        );

        if (!expressionValue) {
            return;
        }

        let selected: Option | undefined;

        if (moduleConfig.addOptions.length === 1 && hasLengthAtLeast(moduleConfig.addOptions, 1)) {
            selected = moduleConfig.addOptions[0];
        } else {
            selected = await this.modalService.openMedium(OptionsModalComponent, {
                label: this.translate.instant(ShellTranslationKey.FormBucketDialogAddFormTitle),
                options: moduleConfig.addOptions,
            });
        }

        if (!selected) {
            return;
        }

        const params: Dictionary<string> = {};

        params.$definition = selected.identifier;
        params[moduleConfig.filterLink.identifier] = expressionValue;
        void this.router.navigate([FormDataPath, pageConfig.bucket, NewItemPath, params]);
    }

    private createDataSource(table: Table, moduleFilter?: AstNode): TableDataSource<TableData> {
        const factory = new TableInputManagerFactory(this.detailContextProvider, this.expParser, undefined, this.hierarchyUnitProvider);
        const tableInputManager = factory.create(table, moduleFilter);

        let inputFilters;
        const sort = SortStatus.fromString(table.defaultSort) ?? undefined;

        if (sort) {
            inputFilters = { sort };
        }

        let datasource: TableDataSource<TableData>;

        switch (table.sourceType) {
            case TableSourceType.Users:
                datasource = new UsersTableDataSource(this.usersClient, table.identifier, tableInputManager, inputFilters) as TableDataSource<TableData>;
                break;
            case TableSourceType.Company:
                datasource = new CompanyTableDataSource(new CompaniesClient(this.client), table.identifier, tableInputManager, inputFilters) as TableDataSource<TableData>;
                break;
            case TableSourceType.Bucket: {
                this.formService.bucket = table.source as string;
                const showCount = checkShowCount(this.config, table);

                datasource = new BucketTableDataSource({
                    shellFormService: this.formService,
                    tableIdentifier: table.identifier,
                    tableInputManager,
                    tableInputs: inputFilters,
                    showCount,
                });
            }
        }

        /**
         * listen to the datasource first load (no pagination for dashboard-table)
         * decide if shows the table expander container or keep it hidden
        */
        this.dataSubscription?.unsubscribe();
        this.dataSubscription = datasource.connect().subscribe({
            next: (result) => {
                this.hide = !result.error && !result.data?.length && this.pageMode === TableDetailTemplate.PageViewHideEmptyTables;
            },
            error: () => {
                this.hide = false;
            },
        });

        return datasource;
    }

    private routeToItem(identifier: string, id: string, table: Table) {
        const segments: any[] = [];

        switch (table.sourceType) {
            case TableSourceType.Bucket:
                segments.push(FormDataPath, table.source, id);
                break;
            case TableSourceType.Users:
                segments.push(identifier, id);
                break;
        }

        if (table.detail) {
            segments.push({ mode: TableDisplayMode.Detail });
        }

        void this.router.navigate(segments);
    }

    private canRouteToItem(item: TableData, tableSource: TableSourceType, bucket?: string): boolean {
        switch (tableSource) {
            case TableSourceType.Bucket: return this.auth.getGrantedInfo(
                PermissionsFunctions.getBucketDocumentPath(this.config.unifii.projectId, bucket as string, item.id as string),
                PermissionAction.Read,
                item,
                this.contextProvider.get(),
            ).granted;
            case TableSourceType.Company: return this.auth.getGrantedInfo(
                PermissionsFunctions.getCompanyPath(item.id),
                PermissionAction.Read,
                item,
                this.contextProvider.get(),
            ).granted;
            case TableSourceType.Users: return this.auth.getGrantedInfo(
                PermissionsFunctions.getUserPath(item.id ? +item.id : undefined),
                PermissionAction.Read,
                item,
                this.contextProvider.get(),
            ).granted;
        }
    }

    private async loadPageCount() {
        this.showCount = !!this.module.pageConfig.table.showCount;
        if (this.dataSource && this.config.unifii.tenantSettings?.features.indexing) {
            this.tableConfig.exhaustAhead = true;
            this.recordCount = await this.dataSource.count;
        }
    }

}
