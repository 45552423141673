import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { TableInputs } from '@unifii/components';
import { CellDisplayDescriptor, ContextProvider, FilterValue, SortStatus, TableComponent, TableConfig, TableDataSource } from '@unifii/library/common';
import { Client, CompaniesClient, Definition, FormData, PermissionAction, TableDetailTemplate, TableSourceType, UsersClient, ensureUfRequestError } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { Config } from 'config';
import { ContentDataResolver } from 'shell/content/content-data-resolver';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { ShellFormService } from 'shell/form/shell-form.service';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { FormDataPath } from 'shell/shell-constants';
import { TableNodeInfo } from 'shell/shell-model';
import { ShellTranslationKey } from 'shell/shell.tk';
import { CompanyTableDataSource } from 'shell/table/companies/company-table-datasource';
import { BucketTableDataSource } from 'shell/table/form-data/bucket-table-datasource';
import { TableData } from 'shell/table/models';
import { TableColumnFactory } from 'shell/table/table-column-factory';
import { checkShowCount, getTableCustomColumnsDisplayDescriptors } from 'shell/table/table-functions';
import { TableInputManagerFactory } from 'shell/table/table-input-manager-factory';
import { UsersTableDataSource } from 'shell/table/users/users-table-datasource';

@Component({
    selector: 'us-dashboard-table',
    templateUrl: './dashboard-table.html',
    styleUrls: ['./dashboard-table.less'],
    providers: [ShellFormService, TableInputManagerFactory],
})
export class DashboardTableComponent implements OnInit, OnDestroy {

    @Input({ required: true }) tableInfo: TableNodeInfo;
    @Input() pageMode = TableDetailTemplate.PageView;

    @Output() hideChange = new EventEmitter<boolean>();

    @ViewChild(TableComponent) protected tableComponent: TableComponent<TableData> | null;
    @HostBinding('class.hide') protected _hide: boolean;

    protected readonly shellTK = ShellTranslationKey;

    protected recordCount: number | undefined;
    protected tableConfig: TableConfig<FormData> | null = null;
    protected error: AppError | undefined;
    protected formsDefinition: Map<string, Definition> = new Map<string, Definition>();
    protected datasource: TableDataSource<TableData> | null = null;
    protected customColumns: CellDisplayDescriptor[] = [];
    protected tableLink: any[];

    private client = inject(Client);
    private config = inject(Config);
    private auth = inject(Authentication);
    private usersClient = inject(UsersClient);
    private errorService = inject(ErrorService);
    private formService = inject(ShellFormService);
    private contextProvider = inject(ContextProvider);
    private dataResolver = inject(ContentDataResolver);
    private inputManagerFactory = inject(TableInputManagerFactory);
    private tableColumnFactory = inject(TableColumnFactory);
    private dataSubscription: Subscription | null;

    get showSeeMoreRow() {
        return this.tableComponent?.status.exhausted === false;
    }

    private get canShowRecordCount() {
        return this.datasource && this.config.unifii.tenantSettings?.features.indexing && this.tableInfo.table.showCount;
    }

    private set hide(v: boolean) {
        if (v === this._hide) {
            return;
        }
        this._hide = v;
        this.hideChange.emit(v);
    }

    async ngOnInit() {
        try {
            this.formService.bucket = this.tableInfo.table.source as string;
            const { tablePageConfig } = await this.dataResolver.getTableData(this.tableInfo.table.identifier);
            const { propertyDescriptors } = tablePageConfig;

            this.tableConfig = {
                columns: this.tableColumnFactory.create(this.tableInfo.table.columns ?? [], propertyDescriptors, this.tableInfo.table.sourceType, false),
                pageSize: this.tableInfo.pageSize,
                row: {
                    link: (item: FormData) => {
                        if (this.canRouteToItem(item)) {
                            return ['/', FormDataPath, this.tableInfo.table.source, item.id];
                        }

                        return [];
                    },
                },
                exhaustAhead: checkShowCount(this.config, this.tableInfo.table),
            };

            this.customColumns = getTableCustomColumnsDisplayDescriptors(propertyDescriptors, tablePageConfig.table.columns);

            // Load data
            this.reload();

            if (this.canShowRecordCount) {
                this.recordCount = await this.datasource?.count;
            }

            this.tableLink = ['/', this.tableInfo.table.identifier];

        } catch (e) {
            this.error = ensureUfRequestError(e, this.errorService.unknownErrorMessage);
        }
    }

    ngOnDestroy() {
        this.dataSubscription?.unsubscribe();
    }

    reload() {
        this.dataSubscription?.unsubscribe();
        this.datasource = this.createDataSource();

        /**
         * listen to the datasource first load (no pagination for dashboard-table)
         * decide if shows the table expander container or keep it hidden
        */
        this.dataSubscription = this.datasource.connect().subscribe({
            next: (result) => {
                this.hide = !result.error && !result.data?.length && this.pageMode === TableDetailTemplate.PageViewHideEmptyTables;
            },
            error: () => {
                this.hide = false;
            },
        });
    }

    private createDataSource(): TableDataSource<TableData> {
        const tableInputManager = this.inputManagerFactory.create(this.tableInfo.table);

        let inputFilters: TableInputs<FilterValue> | undefined;
        const sort = SortStatus.fromString(this.tableInfo.table.defaultSort) ?? undefined;

        if (sort) {
            inputFilters = { sort };
        }

        switch (this.tableInfo.table.sourceType) {
            case TableSourceType.Users:
                return new UsersTableDataSource(this.usersClient, this.tableInfo.table.identifier, tableInputManager, inputFilters) as TableDataSource<TableData>;
            case TableSourceType.Company:
                return new CompanyTableDataSource(new CompaniesClient(this.client), this.tableInfo.table.identifier, tableInputManager, inputFilters) as TableDataSource<TableData>;
            case TableSourceType.Bucket:
                this.formService.bucket = this.tableInfo.table.source as string;

                return new BucketTableDataSource({
                    shellFormService: this.formService,
                    tableIdentifier: this.tableInfo.table.identifier,
                    tableInputManager,
                    tableInputs: inputFilters,
                    showCount: checkShowCount(this.config, this.tableInfo.table),
                });
        }
    }

    private canRouteToItem(item: FormData): boolean {

        switch (this.tableInfo.table.sourceType) {
            case TableSourceType.Bucket:
                return this.auth.getGrantedInfo(
                    PermissionsFunctions.getBucketDocumentPath(this.config.unifii.projectId, this.tableInfo.table.source as string, item.id as string),
                    PermissionAction.Read,
                    item,
                    this.contextProvider.get(),
                ).granted;
            case TableSourceType.Company:
                return this.auth.getGrantedInfo(
                    PermissionsFunctions.getCompanyPath(item.id),
                    PermissionAction.Read,
                    item,
                    this.contextProvider.get(),
                ).granted;
            case TableSourceType.Users:
                return this.auth.getGrantedInfo(
                    PermissionsFunctions.getUserPath(item.id ? +item.id : undefined),
                    PermissionAction.Read,
                    item,
                    this.contextProvider.get(),
                ).granted;
        }
    }

}
