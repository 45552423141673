import { DataPropertyDescriptor, DateWeekDayAndTimeDisplayFormat, DateWeekDayDisplayFormat, FieldDisplayPipe, TemplateStringParser } from '@unifii/library/common';
import { ContentLinkFormData, Dictionary, FieldType, FileData } from '@unifii/sdk';

export const getBucketColumnRenderableValue = (
    propertyValue: any,
    dataPropertyDescriptor: DataPropertyDescriptor,
    fieldDisplayPipe: FieldDisplayPipe,
    templateStringParser: TemplateStringParser,
    itemTemplate?: string,
): string | FileData[] | ContentLinkFormData | undefined => {

    if (dataPropertyDescriptor.type === FieldType.Link) {
         return propertyValue as ContentLinkFormData;
    }

    if (dataPropertyDescriptor.type === FieldType.Repeat && Array.isArray(propertyValue) && itemTemplate) {
        return propertyValue.map((v) => templateStringParser.parse(itemTemplate, v, v)).join('\r\n');
    }

    const format = getFormatByFieldType(dataPropertyDescriptor.type);

    return fieldDisplayPipe.transform(propertyValue, dataPropertyDescriptor.type, dataPropertyDescriptor.options, format) ?? undefined;
};

/**
 * Recursively attempts to access child properties values
 */
export const childValueAccessor = (property: string, data: Dictionary<any>): any =>
    property.split('.').reduce((s, prop) => accessUnknownMember(prop, s), data);

const accessUnknownMember = (prop: string, data: Dictionary<any>): any => {
    try {
        return data[prop];
    } catch (e) { /**/ }
};

const getFormatByFieldType = (type: FieldType): string | undefined => {
    switch (type) {
        case FieldType.Date:
            return DateWeekDayDisplayFormat;
        case FieldType.DateTime:
        case FieldType.ZonedDateTime:
            return DateWeekDayAndTimeDisplayFormat;
        default:
            return;
    }
};
