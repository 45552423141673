import { Inject, Injectable } from '@angular/core';
import { DataPropertyDescriptor, ExpressionParser, FieldDisplayPipe, TableConfigColumn, TemplateStringParser, WindowWrapper, columnDescriptorToCellDisplayDescriptor, isCustomColumn } from '@unifii/library/common';
import { ColumnDescriptor, Company, FormData, TableSourceType, UserInfo, isNotNull } from '@unifii/sdk';

import { MobileScreenWidth } from 'shell/shell-constants';

import { TableData } from './models';
import { getTablePropertyDisplayValue } from './table-functions';

/**
 * @description
 * TableColumnFactory is used by TableContainerManager classes
 */
@Injectable({ providedIn: 'root' })
export class TableColumnFactory {

    constructor(
        @Inject(WindowWrapper) private window: Window,
        private displayPipe: FieldDisplayPipe,
        private expressionParser: ExpressionParser,
        private templateStringParser: TemplateStringParser,
    ) { }

    create(
        columnDescriptors: ColumnDescriptor[],
        propertyDescriptors: Map<string, DataPropertyDescriptor>,
        sourceType: TableSourceType.Bucket,
        isPageTable: boolean,
    ): TableConfigColumn<FormData>[];
    create(
        columnDescriptors: ColumnDescriptor[],
        propertyDescriptors: Map<string, DataPropertyDescriptor>,
        sourceType: TableSourceType.Company,
        isPageTable: boolean,
    ): TableConfigColumn<Company>[];
    create(
        columnDescriptors: ColumnDescriptor[],
        propertyDescriptors: Map<string, DataPropertyDescriptor>,
        sourceType: TableSourceType.Users,
        isPageTable: boolean,
    ): TableConfigColumn<UserInfo>[];
    create(
        columnDescriptors: ColumnDescriptor[],
        propertyDescriptors: Map<string, DataPropertyDescriptor>,
        sourceType: TableSourceType,
        isPageTable: boolean,
    ): TableConfigColumn<TableData>[];
    create(
        columnDescriptors: ColumnDescriptor[],
        propertyDescriptors: Map<string, DataPropertyDescriptor>,
        sourceType: TableSourceType,
        isPageTable: boolean,
    ): TableConfigColumn<TableData>[] {
        return columnDescriptors.map((column) => {

            const config: TableConfigColumn<TableData> = {
                name: column.identifier,
                hidden: this.isMobileScreen ? column.hideOnMobile : column.hideOnDesktop,
            };

            const dataDescriptor = propertyDescriptors.get(column.identifier);

            if (dataDescriptor == null && isCustomColumn(columnDescriptorToCellDisplayDescriptor(column))) {
                config.label = column.heading;
                config.sortable = false;

                return config;
            }

            if (dataDescriptor == null || !dataDescriptor.asDisplay) {
                return;
            }

            config.label = column.heading ?? dataDescriptor.label;
            config.sortable = isPageTable && dataDescriptor.asSort === true;
            config.value = (item) => getTablePropertyDisplayValue(
                this.expressionParser,
                this.templateStringParser,
                this.displayPipe,
                sourceType,
                item,
                column.identifier,
                dataDescriptor.type,
                dataDescriptor.options,
                column.defaultFormat,
                column.itemTemplate,
            );

            return config;
        }).filter(isNotNull);
    }

    private get isMobileScreen() {
        return this.window.innerWidth <= MobileScreenWidth;
    }

}
