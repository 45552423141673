import { Injectable, Type } from '@angular/core';
import { ComponentRegistryOptions, ContentField } from '@unifii/library/smart-forms';
import { UfFormDescriptionListRegistry } from '@unifii/library/smart-forms/input';
import { FieldType } from '@unifii/sdk';

import { ContentLinkDescriptionComponent } from 'discover/components/smart-form/content-link-description.component';

@Injectable()
export class UdFormDescriptionListRegistry extends UfFormDescriptionListRegistry {

    override get(name: string, options?: ComponentRegistryOptions): Type<ContentField> | null {

        if (name as FieldType === FieldType.Link) {
            return ContentLinkDescriptionComponent;
        }

        return super.get(name, options);
    }

}
