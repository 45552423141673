<uf-panel ufMasterDetail class="container">
    <div class="uf-container bg-solid">
        <div class="uf-app-bar transparent pad-sm-left">
            <uf-breadcrumbs [breadcrumbs]="breadcrumbs" [minimize]="true" class="small left" />
            <a *ngIf="itemLink" [routerLink]="itemLink.urlSegments" class="uf-button small primary right">
                {{ itemLink.name }}
            </a>
        </div>
        <div class="pad-top gap-bottom">
            <ng-container *ngFor="let item of tableListItems">
                <div class="uf-box flat">
                    <div *ngIf="item.type === 'heading'" class="uf-app-bar medium">
                        <div class="title">
                            {{item.label}}
                        </div>
                    </div>
                    <uf-dl *ngIf="item.type === 'description'" [items]="[item]"
                        class="pad-sm-top pad-sm-bottom pad-left" />
                    <uf-dl *ngIf="item.type === 'attachments'" class="pad-sm-top pad-sm-bottom pad-left">
                        <dt>{{ item.term }}:</dt>
                        <dd>
                            <div *ngFor="let attachment of item.attachments">
                                <a [id]="attachment.id" attachmentLink>{{attachment.name}}</a>
                            </div>
                        </dd>
                    </uf-dl>
                    <uf-dl *ngIf="item.type === 'link'" class="pad-sm-top pad-sm-bottom pad-left">
                        <dt>{{ item.term }}:</dt>
                        <dd>
                            <us-content-link-url [content]="item.content" [showType]="true" />
                        </dd>
                    </uf-dl>
                    <uf-dl *ngIf="item.type === 'geoLocation'" class="pad-sm-top pad-sm-bottom pad-left">
                        <dt>{{ item.term }}:</dt>
                        <dd>
                            <a [geoLocation]="item.geoLocation" mapViewerLink>{{item.description}}</a>
                        </dd>
                    </uf-dl>
                </div>
            </ng-container>
        </div>

        <us-empty-module-message
            *ngIf="showNoEmptyMessage && parent.pageMode === templateEnumVales.PageViewHideEmptyTables"
            [message]="this.emptyMessage" />

        <div *ngIf="tableModules.length && parent.pageMode !== templateEnumVales.MenuView" class="uf-grid">
            <ng-container *ngFor="let module of tableModules; let i = index">
                <us-table-module [module]="module" [item]="item" [pageMode]="parent.pageMode"
                    [detailContextProvider]="detailContextProvider" (hideChange)="updateHiddenTablesSet(module, $event)"
                    class="col-12 gap-sm-top module" />
            </ng-container>
        </div>
    </div>
</uf-panel>