import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AddActionConfig, DownloadConfig, TableContainerManager, TableInputManager, TableInputs, TableManagerFunctions } from '@unifii/components';
import { CellDisplayDescriptor, CommonTranslationKey, CompanyIdentifiers, ContextProvider, DataPropertyDescriptor, FilterEntry, FilterValue, ModalService, SharedTermsTranslationKey, TableAction, TableConfig, TableRowContext, ToastService, columnDescriptorToCellDisplayDescriptor, isCustomColumn } from '@unifii/library/common';
import { Client, Option, PermissionAction, Table, TableSourceType, UserInfo, UserInvite, UserStatus, UsersClient, getUserStatus } from '@unifii/sdk';
import { UserKeys } from '@unifii/user-provisioning';
import { Subject } from 'rxjs';

import { DiscoverTranslationKey } from 'discover/discover.tk';
import { TableDisplayMode } from 'shell/content/content-node.component';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { CreateUserPath, InviteUserPath, TableSearchMinLength } from 'shell/shell-constants';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TableColumnFactory } from 'shell/table/table-column-factory';
import { TableInputManagerFactory } from 'shell/table/table-input-manager-factory';
import { ModuleInfo, TablePageConfig } from 'shell/table/table-page-config';
import { UsersTableDataSource } from 'shell/table/users/users-table-datasource';

import { checkDownloadRoles } from '../table-functions';

@Injectable()
export class UsersTableContainerManager implements TableContainerManager<UserInfo, FilterValue, FilterEntry> {

    readonly discoverTK = DiscoverTranslationKey;

    tableConfig: TableConfig<UserInfo>;
    tableIdentifier: string;
    showSearch: boolean;
    searchMinLength = TableSearchMinLength;
    addActionConfig?: AddActionConfig;
    downloadConfig?: DownloadConfig;
    customColumns: CellDisplayDescriptor[] = [];
    defaultSort: string | undefined;
    help?: string;

    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();
    updateItem = new Subject<UserInfo | { item: UserInfo; trackBy: keyof UserInfo }>();

    inputManager: TableInputManager<FilterValue, FilterEntry>;

    private tableInputs?: TableInputs<FilterValue>;
    // dependencies
    private client: Client;
    private auth: Authentication;
    private contextProvider: ContextProvider;
    private translate: TranslateService;
    private usersClient: UsersClient;
    private modalService: ModalService;
    private toastService: ToastService;
    private router: Router;
    private route: ActivatedRoute;
    private columnFactory: TableColumnFactory;
    private moduleInfo: ModuleInfo | undefined;

    constructor() {
        this.client = inject(Client);
        this.auth = inject(Authentication);
        this.contextProvider = inject(ContextProvider);
        this.usersClient = inject(UsersClient);
        this.translate = inject(TranslateService);
        this.modalService = inject(ModalService);
        this.toastService = inject(ToastService);
        this.router = inject(Router);
        this.route = inject(ActivatedRoute);
        this.columnFactory = inject(TableColumnFactory);
        this.moduleInfo = inject(ModuleInfo);

        const { table, propertyDescriptors, addOptions, isSearchable } = inject(TablePageConfig);

        this.tableIdentifier = table.identifier;
        this.defaultSort = table.defaultSort;
        this.showSearch = isSearchable;
        this.help = table.help;

        this.inputManager = inject(TableInputManagerFactory).create(table, this.moduleInfo?.filter);

        if (table.hideExport !== true && checkDownloadRoles(table, this.auth.userInfo?.roles ?? [])) {
            this.downloadConfig = {
                name: `${table.title}.csv`,
                getUrl: this.getDownloadUrl.bind(this),
            };
        }

        this.addActionConfig = this.createAddConfig(table, addOptions ?? []);
        this.setManagerConfig(table, propertyDescriptors);
    }

    createDataSource(inputs?: TableInputs<FilterValue>) {
        this.tableInputs = inputs;

        return new UsersTableDataSource(this.usersClient, this.tableIdentifier, this.inputManager, inputs);
    }

    addActionCallback = (identifier: string) => {
        const path = identifier === PermissionAction.Add ? CreateUserPath : InviteUserPath;

        this.router.navigate([path], { relativeTo: this.route });
    };

    private async getDownloadUrl(): Promise<string> {
        const dataSource = this.createDataSource(this.tableInputs);
        const url = dataSource.getDownloadUrl();

        if (!url) {
            throw new Error('Failed to get download url');
        }

        const { token } = await this.client.getDownloadToken(url);

        return `${url}&_dlt=${token}`;
    }

    private setManagerConfig(table: Table, propertyDescriptors: Map<string, DataPropertyDescriptor>) {
        const canInvite = this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.Invite).granted;
        const canDelete = this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUserPath(), PermissionAction.Delete).granted;
        const id = `table_${table.identifier}`;
        const usersListPermissionFields = PermissionsFunctions.mergePermissionsFields([
            this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.List).fieldsPermissions,
        ]);

        this.customColumns = (table.columns ?? []).map(columnDescriptorToCellDisplayDescriptor).filter(isCustomColumn);

        // Only columns of allowed readFields or projection columns (aka custom columns)
        const readableColumns = table.columns?.filter((cd) =>
            !usersListPermissionFields.readFields ||
            usersListPermissionFields.readFields?.find((rf) => {
                
                if (cd.identifier === CompanyIdentifiers.Status) {
                    // 'status' is available when isActive is available
                    return usersListPermissionFields.readFields?.find((f) => f === UserKeys.IsActive);
                }

                return rf === cd.identifier;
            }) ||
            this.customColumns.find((cdd) => cdd.name === cd.identifier),
        );
        
        const columns = this.columnFactory.create(readableColumns ?? [], propertyDescriptors, TableSourceType.Users, true);
        const tableConfig = TableManagerFunctions.createTableConfig(columns, id);

        tableConfig.row = { link: (item: UserInfo) => this.getRowLink(item, table) };
        tableConfig.actions = this.getActions(canInvite);

        if (table.detail == null && (canInvite || canDelete)) {
            tableConfig.selectable = 100;
        }
        this.tableConfig = tableConfig;
    }

    private getRowLink(userInfo: UserInfo, table: Table) {
        const isGranted = this.auth.getGrantedInfo(PermissionsFunctions.getUserPath(+(userInfo.id as string)), PermissionAction.Read, userInfo, this.contextProvider.get()).granted;

        if (isGranted) {
            if (table.detail) {

                // if table detail module
                if (this.moduleInfo) {
                    return ['/', this.moduleInfo.identifier, userInfo.id, { mode: TableDisplayMode.Detail }];
                }

                return [userInfo.id, { mode: TableDisplayMode.Detail }];
            }

            // if table detail module
            if (this.moduleInfo) {
                return ['/', this.moduleInfo.identifier, userInfo.id];
            }

            return [userInfo.id];
        }

        return [];
    }

    private getActions(canInvite = false): TableAction<UserInfo>[] | undefined {
        return [{
            label: this.translate.instant(SharedTermsTranslationKey.ActionDelete),
            predicate: (row) => this.canDeleteUser(row.$implicit),
            action: (rows) => {
                const users = (rows as TableRowContext<UserInfo>[]).map((r) => r.$implicit);

                this.delete(users);
            },
        }, {
            label: this.translate.instant(DiscoverTranslationKey.UserActionResendInvite),
            predicate: (row) => this.canResendInvite(row.$implicit, canInvite),
            action: (rows) => {
                const users = (rows as TableRowContext<UserInfo>[]).map((row) => row.$implicit);

                this.reInviteUsers(users);
            },
        }];
    }

    private canDeleteUser = (userInfo: UserInfo) => {
        // TODO reuse Discover UserFormPermissionsController.canDelete(user)
        const id = +(userInfo.id as string);
        const path = PermissionsFunctions.getUserPath(id);

        return getUserStatus(userInfo) === UserStatus.Pending &&
            this.auth.getGrantedInfo(path, PermissionAction.Delete, userInfo, this.contextProvider.get()).granted;
    };

    private canResendInvite = (user: UserInfo, canInvite: boolean) => canInvite && getUserStatus(user) === UserStatus.Pending;

    private async delete(users: UserInfo[]): Promise<void> {
        const confirmed = await this.modalService.openConfirm({ message: this.translate.instant(DiscoverTranslationKey.UserInviteDeleteModalMessage) });

        if (!confirmed) {
            return;
        }

        try {
            for (const { id } of users) {
                await this.usersClient.delete(id + '');
            }
            this.toastService.success(this.translate.instant(ShellTranslationKey.DeleteUserModalSuccess));
            this.reload.next();
        } catch (e) {
            this.toastService.error(this.translate.instant(ShellTranslationKey.DeleteUserModalFail));
        }
    }

    private async reInviteUsers(users: UserInfo[]): Promise<void> {
        const confirmed = await this.modalService.openConfirm({ message: this.translate.instant(DiscoverTranslationKey.UserInviteModalMessage) });

        if (!confirmed) {
            return;
        }

        try {
            const invites = users.map((user) => ({ email: user.email as string, username: user.username, company: user.company } as UserInvite));

            await this.usersClient.bulkInvite(invites);
            this.toastService.success(this.translate.instant(CommonTranslationKey.FeedbackSuccess));
            this.reload.next();
        } catch (e) {
            this.toastService.error(this.translate.instant(CommonTranslationKey.FeedbackFail));
        }
    }

    private createAddConfig(table: Table, options: Option[]): AddActionConfig | undefined {
        /**
         * Hide actions if table has a detail, actions are only used when a table is configured with a direct user form
         * as it's assumed that table is created with the goal of editing users. In the future we may add configuration around allowing table actions in the configuration
         */
        if (table.detail != null || !options.length) {
            return;
        }

        return {
            label: this.translate.instant(this.discoverTK.UsersAddTitle),
            options,
        };
    }

}

