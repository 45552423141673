import { Component, HostBinding, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DescriptionListItem, Modal, ModalData, ModalRuntime, SharedTermsTranslationKey, UfControl, ValidatorFunctions, WindowWrapper } from '@unifii/library/common';
import { AppAuthProviderConfiguration, TenantClient, TenantSettings } from '@unifii/sdk';

import { InAppBrowserService } from 'capacitor/in-app-browser.service';
import { Config } from 'config';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ErrorService } from 'shell/errors/error.service';
import { SSOService } from 'shell/services/sso.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

export interface ContactInfoModalData {
    tenantSettings?: TenantSettings;
}

@Component({
    templateUrl: './contact-info-modal.html',
    styleUrls: ['../../shell/styles/external-branding.less'],
})
export class ContactInfoModalComponent implements Modal<ContactInfoModalData, void> {

    @HostBinding('class.ud-card') class = true;
    @HostBinding('class.grid') class1 = true;

    readonly discoverTK = DiscoverTranslationKey;

    contactDetails: DescriptionListItem[] | null;
    companyControl: UfControl;
    company: string;
    name: string | null;

    protected providers: AppAuthProviderConfiguration[] = [];

    constructor(
        private tenantClient: TenantClient,
        @Inject(ModalData) public data: ContactInfoModalData,
        @Inject(Config) public config: Config,
        public runtime: ModalRuntime<ContactInfoModalData, void>,
        private translate: TranslateService,
        private ssoService: SSOService,
        private userAccessManager: UserAccessManager,
        private inAppBrowser: InAppBrowserService,
        private errorService: ErrorService,
        @Inject(WindowWrapper) private window: Window,
    ) {
        this.contactDetails = this.getContactDetails(this.data.tenantSettings);

        this.providers = this.ssoService.buildProviderWithLabels(true);

        this.companyControl = new UfControl([
            ValidatorFunctions.custom((v) => this.config.unifii.tenant != null || (v != null && v !== ''), this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired)),
            ValidatorFunctions.pattern(/^[A-Za-z0-9.-]+$/, this.translate.instant(SharedTermsTranslationKey.ValidatorInvalidCharacters)),
            ValidatorFunctions.custom((v) => !/\s/.test(v), this.translate.instant(SharedTermsTranslationKey.ValidatorInvalidSpaces)),
        ]);
    }

    close() {
        this.runtime.close();
    }

    retrieve() {
        this.companyControl.setSubmitted(true);

        if (this.companyControl.invalid) {
            return;
        }

        this.name = this.company;
        this.load();
    }

    async load() {

        this.config.unifii.tenant = this.name ?? undefined;
        const settings = await this.tenantClient.getSettings();

        this.contactDetails = this.getContactDetails(settings);
    }

    protected async providerSignIn(provider: AppAuthProviderConfiguration, username?: string): Promise<void> {

        this.userAccessManager.showError(null);

        try {

            const redirectUri = this.ssoService.loginRedirectUri;
            const providerUrl = await this.ssoService.getProviderUrl(provider, redirectUri, username);

            if (!providerUrl) {
                throw new Error('');
            }

            if (this.inAppBrowser.isAvailable) {
                this.inAppBrowser.open(providerUrl);
            } else {
                this.window.location.href = providerUrl;
            }

        } catch (error) {
            /** device registration fail,  */
            this.userAccessManager.showError(this.errorService.createError('', error));
        }
    }

    private getContactDetails(tenantSettings: TenantSettings | undefined): DescriptionListItem[] | null {

        if (tenantSettings == null) {
            return null;
        }

        const details = [];

        if (tenantSettings.contactName != null) {
            details.push({ term: this.translate.instant(DiscoverTranslationKey.ContactInfoNameLabel), description: tenantSettings.contactName });
        }

        if (tenantSettings.contactEmail != null) {
            details.push({ term: this.translate.instant(DiscoverTranslationKey.ContactInfoEmailLabel), description: tenantSettings.contactEmail });
        }

        if (tenantSettings.contactPhone != null) {
            details.push({ term: this.translate.instant(DiscoverTranslationKey.ContactInfoPhoneLabel), description: tenantSettings.contactPhone });
        }

        return details;
    }

}
