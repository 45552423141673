import { Injectable, inject } from '@angular/core';
import { ClaimConfig, CompaniesClient, Company, ErrorType, Query, Role, SortDirections, TenantClient, UfError, UserInfo, UsersClient } from '@unifii/sdk';
import { UserProvisioningProvider } from '@unifii/user-provisioning';

@Injectable()
export class UserProvisioning implements UserProvisioningProvider {

    // complete-registration work outside of the authenticated scope
    skipGetUserByUsername: boolean;
    // complete-registration work outside of the authenticated scope
    skipCheckEmail: boolean;
    
    private tenantClient = inject(TenantClient);
    private usersClient = inject(UsersClient);
    private companiesClient = inject(CompaniesClient);

    async queryRoles(q?: string): Promise<Role[]> {
        const roles = await this.tenantClient.getRoles();

        if (!q) {
            return roles;
        }

        const lowerQ = q.toLowerCase();

        return roles.filter((role) => role.name.toLowerCase().includes(lowerQ));
    }

    queryCompanies(q?: string, ids?: string[]): Promise<Company[]> {
        const query = new Query();
        
        query.sort('name', SortDirections.Ascending);
        
        if (q) {
            query.q(q);
        }
        
        if (ids?.length) {
            query.in('id', ids);
        }
        
        return this.companiesClient.query(query);
    }

    getUserClaimConfig(): Promise<ClaimConfig[]> {
        return this.tenantClient.getUserClaims();
    }

    getUserByUsername(username: string): Promise<UserInfo> {
        if (this.skipGetUserByUsername) {
            throw new UfError('user not found', ErrorType.NotFound);
        }

        return this.usersClient.getByUsername(username);
    }

    getCompany(id: string): Promise<Company> {
        return this.companiesClient.get(id);
    }

    checkEmail(email: string): Promise<boolean> {
        if (this.skipCheckEmail) {
            return Promise.resolve(false);
        }

        return this.usersClient.checkEmail(email);
    }

}
