<div class="col-1of1">
    <div class="ud-card-actions">
        <button (click)="close()" type="button" class="uf-action tertiary small">
            <uf-icon name="close" />
        </button>
    </div>

    <h3>{{discoverTK.ContactInfoTitle | translate}}</h3>
    <ng-container *ngIf="!contactDetails">
        <p>{{discoverTK.CompanyHelpMessage | translate}}</p>
    </ng-container>

    <ng-container *ngIf="contactDetails">
        <p>{{discoverTK.ContactInfoMessage | translate}}</p>
        <uf-description-list *ngIf="contactDetails.length" [items]="contactDetails" class="small small-label" />
    </ng-container>

    <ng-container *ngIf="providers.length">
        <h3>{{discoverTK.SupportLoginLabel | translate}}</h3>
        <ng-container *ngFor="let provider of providers">
            <div class="col gap-bottom">
                <label *ngIf="provider.providerLoginLabel">{{provider.providerLoginLabel}}</label>
                <button (click)="providerSignIn(provider)" type="button" class="authentication-provider grow">
                    <img [src]="provider.loginIcon" />
                    <p class="small">{{provider.loginLabel}}</p>
                </button>
            </div>
        </ng-container>
    </ng-container>

</div>