import { Location } from '@angular/common';
import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, CommonTranslationKey, ContextProvider, FilterEntry, FilterValue, ModalService, RuntimeDefinition, SharedTermsTranslationKey, ToastService } from '@unifii/library/common';
import { FormConfiguration, FormFunctions, FormSettings, amendFormData } from '@unifii/library/smart-forms';
import { PrintConfig, PrintFormSelectStyleModalComponent, SubmitArgs, UfFormComponent } from '@unifii/library/smart-forms/input';
import { Dictionary, FormData, ParentFormData, PermissionAction, UserContext, ensureUfRequestError } from '@unifii/sdk';
import { Subscription, first, firstValueFrom, interval } from 'rxjs';

import { Config } from 'config';
import { DiscoverContext } from 'discover/discover-context';
import { FormContent } from 'shell/content/content-types';
import { editedData } from 'shell/decorator/edited-data.decorator';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { SaveOutput, SaveResult, ShellFormService } from 'shell/form/shell-form.service';
import { NavigationService } from 'shell/nav/navigation.service';
import { FormDataState } from 'shell/offline/forms/interfaces';
import { OfflineQueue } from 'shell/offline/forms/offline-queue';
import { Authentication } from 'shell/services/authentication';
import { BreadcrumbsService } from 'shell/services/breadcrumbs.service';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { FormDataPath } from 'shell/shell-constants';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TablePageConfig } from 'shell/table/table-page-config';

import { ConflictModalAction, ConflictModalComponent } from './conflict-modal.component';

interface ParentFormInfo {
    label: string;
    routerLink: any[];
}

/**
 * FormComponent
 * This class is created by the ContentNodeComponent, which is responsible for:
 *  - Catching any load errors
 *  - Catching any basic ACL errors
 *  - Show loading Skeleton
 *  - Resolving Data, FormData and Definition
 *
 * to route to this component using the ContentNode use the following commands
 * [`/[bucket],[id]
 * eg: /abc/123
 */
@Component({
    templateUrl: './form.html',
    styleUrls: ['./form.less'],
    providers: [BreadcrumbsService],
})

export class FormComponent implements OnInit, OnDestroy, FormContent {

    @editedData protected edited: boolean;

    // Status
    reloading = false;
    newSubmission: boolean;
    formLabel: string;
    isDisabled: boolean;
    busy: boolean;
    triggerError: AppError;
    user: UserContext | undefined;
    breadcrumbs: Breadcrumb[] = [];
    title: string;

    // Form
    definition: RuntimeDefinition;
    formData?: FormData;
    formConfig: FormConfiguration;
    printConfig: PrintConfig | undefined;
    parentInfo?: ParentFormInfo;

    protected readonly shellTK = ShellTranslationKey;
    protected readonly commonTK = CommonTranslationKey;

    private _formComponent?: UfFormComponent;
    private changesSubscriptions = new Subscription();
    private revisionSubscription: Subscription | undefined;
    private location = inject(Location);

    private route = inject(ActivatedRoute);
    private context = inject(DiscoverContext);
    private navigationService = inject(NavigationService);
    private modalService = inject(ModalService);
    private toastService = inject(ToastService);
    private translate = inject(TranslateService);
    private errorService = inject(ErrorService);
    private router = inject(Router);
    private element = inject(ElementRef<HTMLElement>);
    private breadcrumbsService = inject(BreadcrumbsService);
    private config = inject(Config);
    private formService = inject(ShellFormService);
    private offlineQ = inject(OfflineQueue);
    private ngZone = inject(NgZone);
    private settings = inject(FormSettings);
    private contextProvider = inject(ContextProvider);
    private auth = inject(Authentication);
    private tableManager = inject<TableContainerManager<FormData, FilterValue, FilterEntry>>(TableContainerManager, { optional: true });
    private tableConfig = inject(TablePageConfig, { optional: true });

    get bucketLabel(): string | undefined {
        return this.navigationService.current?.name;
    }

    ngOnInit() {

        this.busy = false;
        this.user = this.contextProvider.get().user;
        // eslint-disable-next-line disable-autofix/@typescript-eslint/non-nullable-type-assertion-style
        this.isDisabled = this.getDisabledStatus(this.config.unifii.projectId, this.definition.bucket as string, this.formData);
        this.formLabel = this.getFormLabel(this.formData);
        // eslint-disable-next-line disable-autofix/@typescript-eslint/non-nullable-type-assertion-style
        this.formService.bucket = this.definition.bucket as string;
        this.newSubmission = this.formData == null;

        this.updateTitle();
        this.applyURLParamsAutofill();

        this.formConfig = {
            optionalCancelButtonLabel: this.translate.instant(SharedTermsTranslationKey.ActionCancel),
            optionalSubmitButtonLabel: this.translate.instant(SharedTermsTranslationKey.ActionSubmit),
        };

        if (this.formData == null) {
            this.formData = amendFormData(this.formData, this.definition);
        }

        this.settings.uploader = this.formService.getFileUploader(this.formData.id ?? '');

        this.initConflictDetection();
        void this.setParentInfo();
    }

    ngOnDestroy() {
        this.unsubscribeToFormChanges();
        this.revisionSubscription?.unsubscribe();
    }

    @ViewChild(UfFormComponent, { static: false }) private set formComponent(v: UfFormComponent | undefined) {
        this._formComponent = v;
        this.updateFormChangesSubscription();
    }

    private get formComponent(): UfFormComponent | undefined {
        return this._formComponent;
    }

    private get breadcrumbTitle(): string {
        let title = this.newSubmission ? this.translate.instant(SharedTermsTranslationKey.NewLabel) as string :
            this.formData?._seqId ?? this.title;

        if (this.edited) {
            title = title + ' *';
        }

        return title;
    }

    protected onEdited(edited: boolean) {
        this.edited = edited;
        this.updateTitle();
    }

    protected async print() {

        if (!this.settings.uploader) {
            return;
        }

        const summary = await this.modalService.openFit(PrintFormSelectStyleModalComponent, null);

        if (summary != null) {
            this.printConfig = {
                definition: this.definition,
                data: JSON.parse(JSON.stringify(this.formData)),
                logoUrl: this.context.project?.logo?.url,
                uploader: this.settings.uploader,
                summary,
            };
        }
    }

    protected async save(args: SubmitArgs) {

        if (this.busy) {
            return;
        }

        const path = this.newSubmission ?
            PermissionsFunctions.getBucketDocumentsPath(this.config.unifii.projectId, this.formService.bucket) :
            // eslint-disable-next-line disable-autofix/@typescript-eslint/non-nullable-type-assertion-style
            PermissionsFunctions.getBucketDocumentPath(this.config.unifii.projectId, this.formService.bucket, args.data.id as string);

        const action = this.newSubmission ?
            PermissionAction.Add :
            PermissionAction.Update;

        if (!this.auth.getGrantedInfo(path, action, args.data, this.contextProvider.get()).granted) {
            this.toastService.info(this.translate.instant(ShellTranslationKey.ErrorRequestUnauthorized));

            return;
        }

        let saveOutput: SaveOutput | undefined;

        try {
            this.busy = true;
            this.unsubscribeToFormChanges();

            saveOutput = await this.formService.save(args.data, this.definition);

            if (saveOutput.result === SaveResult.Failed) {
                const error = this.errorService.createSaveError('form');

                this.toastService.error(error.message);
                this.busy = false;
                this.updateFormChangesSubscription();

                return;
            }

            if (saveOutput.result === SaveResult.Conflict) {
                this.busy = false;
                const result = await this.onConflictDetected();

                if (result !== 'Save') {
                    this.updateFormChangesSubscription();
                }

                return;
            }

            args.done(saveOutput.data);
            this.edited = false;
            this.busy = false;

            if (saveOutput.result === SaveResult.Queued) {
                this.toastService.warning(this.translate.instant(ShellTranslationKey.FormFeedbackQueued));
                this.back();

                return;
            }

            if (this.tableManager) {
                this.tableManager.updateItem?.next({ item: saveOutput.data, key: 'id' });
            }

            this.toastService.success(this.translate.instant(ShellTranslationKey.FormFeedbackSaved));

            if (!saveOutput.data) {
                this.back();

                return;
            }

            if (FormFunctions.canKeepEditingOnNext(this.definition.fields, saveOutput.data, this.user?.roles)) {
                this.updateFormChangesSubscription();

                return;
            }

            this.back();

        } catch (e) {
            const error = ensureUfRequestError(e, 'Form');

            this.toastService.error(error.message);
        } finally {
            this.busy = false;
        }
    }

    protected back() {
        if (this.tableConfig) {
            void this.router.navigate(['..'], { relativeTo: this.route });

            return;
        }

        this.navigationService.back(true);
    }

    private updateTitle() {
        this.breadcrumbsService.title = this.breadcrumbTitle;
        if (this.tableConfig) {
            this.breadcrumbs = this.breadcrumbsService.getBreadcrumbs();
        }
    }

    /** Override the Definition fields autofill based on the params */
    private applyURLParamsAutofill() {

        if (!this.newSubmission) {
            return;
        }

        const autoFillsParamMap = this.route.snapshot.paramMap;
        const autoFills = {} as Dictionary<any>;

        for (const k of autoFillsParamMap.keys) {
            autoFills[k] = autoFillsParamMap.get(k);
        }

        FormFunctions.amendDefinitionAutofills(this.definition, autoFills);
    }

    private initConflictDetection() {
        if (!this.formData || this.isDisabled || this.newSubmission) {
            return;
        }

        this.revisionSubscription?.unsubscribe();
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.revisionSubscription = interval(15000).subscribe(async() => {
            // eslint-disable-next-line disable-autofix/@typescript-eslint/non-nullable-type-assertion-style
            const latestRevision = await this.formService.getFormDataRevision(this.formData?.id as string);

            if (latestRevision && this.formData?._rev !== latestRevision) {
                this.revisionSubscription?.unsubscribe();
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                this.onConflictDetected();
            }
        });
    }

    private async onConflictDetected(): Promise<ConflictModalAction | undefined> {
        const result = await this.modalService.openMedium(ConflictModalComponent, undefined, { guard: true });

        switch (result) {

            case 'Save':
                // eslint-disable-next-line disable-autofix/@typescript-eslint/non-nullable-type-assertion-style
                await this.offlineQ.save(this.formData as FormData, this.definition, { status: FormDataState.Conflicted });
                this.edited = false;
                this.back();

                return result;

            case 'Discard': {
                // eslint-disable-next-line disable-autofix/@typescript-eslint/non-nullable-type-assertion-style
                const formData = await this.formService.getFormData(this.formData?.id as string);

                if (!this.definition.hasRollingVersion) {
                    // Only reload the FormData, the Definition don't need update
                    this.formData = formData;
                    this.edited = false;

                    return result;
                }

                const definition = await this.formService.getFormDefinition(this.definition.identifier);

                this.reloading = true;
                await firstValueFrom(this.ngZone.onStable.pipe(first()));
                this.ngZone.runTask(() => {
                    this.ngOnDestroy();
                    this.formData = formData;
                    this.definition = definition;
                    this.ngOnInit();
                    this.edited = false;
                    this.reloading = false;
                });

                return result;
            }
            default:
                return result;
        }
    }

    private async setParentInfo() {
        if (this.formData?._parent == null) {
            return;
        }
        this.parentInfo = await this.getParentInfo(this.formData._parent);
    }

    private getDisabledStatus(projectId: string, bucket: string, formData?: FormData): boolean {
        if (!formData) {
            return !this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getBucketDocumentsPath(projectId, bucket), PermissionAction.Add).granted;
        }

        // eslint-disable-next-line disable-autofix/@typescript-eslint/non-nullable-type-assertion-style
        return !this.auth.getGrantedInfo(PermissionsFunctions.getBucketDocumentPath(projectId, bucket, formData.id as string), PermissionAction.Update, formData, this.contextProvider.get()).granted;
    }

    private updateFormChangesSubscription() {
        this.unsubscribeToFormChanges();

        if (!this.formComponent) {
            return;
        }

        // scroll to active section on Next Flag
        this.changesSubscriptions.add(this.formComponent.workflow.updated.subscribe((formData) => {

            const nextSections = FormFunctions.targetSectionNextCondition(this.definition.fields, formData._action, formData._state);

            if (!nextSections.length) {
                return;
            }

            if (this.newSubmission) {
                this.newSubmission = false;
                const newRoute = `${this.router.url.substring(0, this.router.url.lastIndexOf('/'))}/${this.formData?.id}`;

                this.location.replaceState(newRoute);
                this.updateTitle();
            }

            setTimeout(() => {
                FormFunctions.scrollToActiveSection(this.element.nativeElement);
            }, 0);
        }));
    }

    private unsubscribeToFormChanges() {
        this.changesSubscriptions.unsubscribe();
        this.changesSubscriptions = new Subscription();
    }

    private async getParentInfo(parent: ParentFormData): Promise<{ label: string; routerLink: any[] }> {

        const seqId = parent.seqId || parent.id;
        const routerLink = ['/', FormDataPath, parent.bucket, parent.id];

        try {
            if (parent.definitionIdentifier) {
                const definition = await this.formService.getFormDefinition(parent.definitionIdentifier);

                return {
                    label: `${definition.label} - ${seqId}`,
                    routerLink,
                };
            }
        } catch (e) { /**/ }

        return {
            label: seqId,
            routerLink,
        };
    }

    private getFormLabel(formData?: FormData): string {

        if (formData == null) {
            return `${this.definition.label} - ${this.translate.instant(SharedTermsTranslationKey.NewLabel)}`;
        }

        // eslint-disable-next-line disable-autofix/@typescript-eslint/no-unnecessary-condition
        if (formData?._seqId) {
            return `${this.definition.label} - ${formData._seqId}`;
        }

        return `${this.definition.label}`;
    }

}
