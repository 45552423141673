import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ModalService } from '@unifii/library/common';
import { Observable, from, of } from 'rxjs';
import { catchError, flatMap, map, mergeMap } from 'rxjs/operators';

import { Config } from 'config';
import { UpdateProgressComponent } from 'discover/offline/update-progress.component';
import { OfflineManager } from 'shell/offline/offline-manager';
import { Authentication } from 'shell/services/authentication';

export const offlineContentGuard: CanActivateFn = () => {

    const config = inject(Config);
    const modalService = inject(ModalService);
    const offlineManager = inject(OfflineManager);
    const authentication = inject(Authentication);

    if (!config.unifii.offline) {
        return of(true);
    }

    return of(null).pipe(
        flatMap(() => offlineManager.openContentDB()),
        map(() => true),
        catchError((error) => {
            console.info('OfflineGuard: no content available, update needed', error);

            return updateContent(modalService, offlineManager, authentication);
        }),
    );
};

const updateContent = (modalService: ModalService, offlineManager: OfflineManager, authentication: Authentication): Observable<boolean> => {

    return from(modalService.openFit(
        UpdateProgressComponent,
        { progress: offlineManager.updateContent() },
        { guard: true },
    )).pipe(mergeMap((updated) => {

        if (!updated) {
            // If no existing database we have no choice but to logout
            return from(authentication.logout());
        }

        return of(updated);
    }));
};
