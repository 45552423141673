import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb, DataPropertyDescriptor, DescriptionListDescription, DescriptionListItem, FieldDisplayPipe, FileUploader, FormDefinitionMetadataIdentifiers, GoogleLocationProvider, LocationProvider, TemplateStringParser } from '@unifii/library/common';
import { stringsCaseInsensitiveLocalCompare } from '@unifii/library/smart-forms';
import { ClaimConfig, Company, ContentLinkFormData, DataSourceType, FieldType, FileData, FormData, GeoLocation, PermissionAction, TableDetailModule, TableDetailTemplate, TableFieldDescriptor, TableIdentifierFieldDescriptor, TableSourceType, TenantClient, UserInfo, isArrayOfType, isDictionary, isString, isStringNotEmpty } from '@unifii/sdk';
import { ProvisioningDescriptionService, UserKeys } from '@unifii/user-provisioning';

import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { TableItemLink } from 'shell/content/content-types';
import { ShellService } from 'shell/core/shell.service';
import { ShellFormService } from 'shell/form/shell-form.service';
import { Authentication } from 'shell/services/authentication';
import { BreadcrumbsService } from 'shell/services/breadcrumbs.service';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { childValueAccessor, getBucketColumnRenderableValue } from 'shell/services/schema-field-functions';
import { FormDataPath } from 'shell/shell-constants';
import { TableData } from 'shell/table/models';
import { TableModuleConfig, TablePageConfig } from 'shell/table/table-page-config';

import { TableDetailContextProvider } from './table-detail-context-provider';
import { TableDetailPageComponent } from './table-detail-page.component';

export interface TableModule {
    detailModule: TableDetailModule;
    pageConfig: TablePageConfig;
    moduleConfig?: TableModuleConfig;
}

interface TableHeadingItem {
    type: 'heading';
    label: string;
}

type TableDescriptionListItem = DescriptionListItem & {
    type: 'description';
}

type TableAttachmentsItemAttachmentInfo = {
    id: string;
    name: string;
}
interface TableAttachmentsItem {
    type: 'attachments';
    term: string;
    attachments: TableAttachmentsItemAttachmentInfo[];
}

interface TableContentLinkItem {
    type: 'link';
    term?: string;
    content: ContentLinkFormData;
}

interface TableGeoLocationItem {
    type: 'geoLocation';
    term: string;
    description: string;
    geoLocation: GeoLocation;
}

type TableListItem = TableHeadingItem | TableDescriptionListItem | TableAttachmentsItem | TableContentLinkItem | TableGeoLocationItem;

@Component({
    selector: 'us-table-detail',
    templateUrl: './table-detail.html',
    styleUrls: ['./table-detail.less'],
    providers: [
        BreadcrumbsService,
        {
            provide: FileUploader,
            useFactory: (route: ActivatedRoute, shellFormService: ShellFormService) =>
                shellFormService.getFileUploader(route.snapshot.params.id as string),
            deps: [ActivatedRoute, ShellFormService],
        },
    ],
})
export class TableDetailComponent implements OnInit {

    itemLink?: TableItemLink | undefined;

    protected readonly discoverTK = DiscoverTranslationKey;
    protected readonly templateEnumVales = TableDetailTemplate;

    protected tableListItems: TableListItem[] = [];
    protected breadcrumbs: Breadcrumb[];
    protected tableModules: TableModule[] = [];
    protected detailContextProvider: TableDetailContextProvider;
    protected item: TableData;
    protected showModules: boolean;
    protected showNoEmptyMessage = false;
    protected parent = inject(TableDetailPageComponent);
    protected emptyMessage?: string;

    private title: string;
    private sourceType: TableSourceType; // TODO: convert to template once in console
    private userClaims: ClaimConfig[];
    private fields: TableFieldDescriptor[];
    private propertyDescriptors: Map<string, DataPropertyDescriptor>;
    private hiddenTablesSet = new Set<TableModule>();
    private fieldDisplayPipe = inject(FieldDisplayPipe);
    private shell = inject(ShellService);
    private templateStringParser = inject(TemplateStringParser);
    private breadcrumbsService = inject(BreadcrumbsService);
    private auth = inject(Authentication);
    private context = inject(DiscoverContext);
    private tenantClient = inject(TenantClient);
    private provisioningDescriptionService = inject(ProvisioningDescriptionService);
    private locationProvider = inject(LocationProvider);

    async ngOnInit() {

        this.loadTableDetailServiceData();

        if (this.sourceType === TableSourceType.Users && this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getDefaultClaimsPath(), PermissionAction.List).granted) {
            this.userClaims = await this.tenantClient.getUserClaims();
        }

        await this.refreshDetails();

        this.breadcrumbs = this.breadcrumbsService.getBreadcrumbs();
        // Remove breadcrumb from mode=details
        this.breadcrumbs.splice(1, 1);

        this.emptyMessage = this.parent.tablePageConfig.table.detail?.emptyMessage;

    }

    protected updateHiddenTablesSet(tableInfo: TableModule, hidden: boolean) {
        if (!hidden) {
            this.hiddenTablesSet.delete(tableInfo);
        } else {
            this.hiddenTablesSet.add(tableInfo);
        }

        this.showNoEmptyMessage = !!this.tableModules.length && this.tableModules.length === this.hiddenTablesSet.size;
    }

    private async refreshDetails() {

        const listItems: TableListItem[] = [];

        for (const field of this.fields) {
            if (field.type === 'Heading') {
                const headingItem = { type: 'heading', label: field.value } as TableHeadingItem;

                // An immediate previous 'heading' is considered empty, replace it with current one
                if (listItems[listItems.length - 1]?.type === 'heading') {
                    listItems[listItems.length - 1] = headingItem;
                } else {
                    listItems.push(headingItem);
                }
                continue;
            }

            const description = await this.toDescription(field);

            if (description) {
                listItems.push(description);
            }
        }

        // Remove last Heading if empty, not followed by a value item
        if (listItems[listItems.length - 1]?.type === 'heading') {
            listItems.pop();
        }

        this.tableListItems = listItems;
    }

    // eslint-disable-next-line complexity
    private async toDescription(descriptor: TableIdentifierFieldDescriptor): Promise<Exclude<TableListItem, TableHeadingItem> | undefined> {

        switch (this.sourceType) {

            case TableSourceType.Bucket: {
                let hrefInfos: DescriptionListDescription[] | undefined;
                const dataPropertyDescriptor = this.propertyDescriptors.get(descriptor.identifier);

                if (!dataPropertyDescriptor?.asDisplay) {
                    return;
                }

                const formData = this.item as FormData;
                const term = descriptor.label ?? dataPropertyDescriptor.label;
                const propertyValue = childValueAccessor(dataPropertyDescriptor.identifier, formData);
                const value = getBucketColumnRenderableValue(
                    propertyValue,
                    dataPropertyDescriptor,
                    this.fieldDisplayPipe,
                    this.templateStringParser,
                    descriptor.itemTemplate,
                );

                if (!value) {
                    return {
                        type: 'description',
                        term,
                    };
                }

                if (this.locationProvider instanceof GoogleLocationProvider && [FieldType.GeoLocation, FieldType.Address].includes(dataPropertyDescriptor.type) && isString(value)) {
                    return {
                        type: 'geoLocation',
                        term,
                        description: value,
                        geoLocation: propertyValue,
                    };
                }

                // Add a link for DS Bucket mapped output field _seqId
                if (dataPropertyDescriptor.type === FieldType.Text && dataPropertyDescriptor.identifier.endsWith(FormDefinitionMetadataIdentifiers.SeqId)) {
                    const parentDataPropertyDescriptor = descriptor.identifier.includes('.') ?
                        this.propertyDescriptors.get(descriptor.identifier.substring(0, descriptor.identifier.lastIndexOf('.'))) :
                        undefined;
                    const parentDataValue = parentDataPropertyDescriptor ?
                        childValueAccessor(parentDataPropertyDescriptor.identifier, formData) :
                        undefined;

                    if (parentDataPropertyDescriptor?.sourceConfig &&
                        parentDataPropertyDescriptor.sourceConfig.type === DataSourceType.Bucket &&
                        isDictionary(parentDataValue) && isStringNotEmpty(parentDataValue._id)) {
                            const bucketId = parentDataPropertyDescriptor.sourceConfig.id;
                            const accessPath = PermissionsFunctions.getBucketDocumentPath(this.context.project?.id ?? '', bucketId, parentDataValue._id);

                            if (this.auth.getGrantedInfoWithoutCondition(accessPath, PermissionAction.Read).granted) {
                                hrefInfos = [{
                                    href: '/' + [FormDataPath, bucketId, parentDataValue._id].join('/'),
                                    hrefTarget: '_blank',
                                }];
                            }
                        }
                }

                if ([FieldType.FileList, FieldType.ImageList].includes(dataPropertyDescriptor.type) && Array.isArray(propertyValue)) {

                    // TODO why not using `value` instead of `propertyValue` ?
                    const files = propertyValue as FileData[];
                    const attachments: TableAttachmentsItemAttachmentInfo[] = [];

                    for (const file of files) {
                        if (!file.id) {
                            continue;
                        }

                        attachments.push({ id: file.id, name: file.name });
                    }

                    if (!attachments.length) {
                        return undefined;
                    }

                    return {
                        type: 'attachments',
                        term,
                        attachments,
                    } as TableAttachmentsItem;

                }

                if (typeof value === 'object') {
                    return {
                        type: 'link',
                        term,
                        content: value,
                    } as TableContentLinkItem;
                }

                hrefInfos = hrefInfos ?? this.getHrefInfo(value, dataPropertyDescriptor);
                const description = !hrefInfos ? value : hrefInfos.map((info) => ({ description: value, href: info.href, hrefTarget: info.hrefTarget, routerLink: info.routerLink }));

                return {
                    type: 'description',
                    term,
                    description,
                } as TableDescriptionListItem;
            }

            case TableSourceType.Users: {

                const userPropertyIdentifier = descriptor.identifier as keyof UserInfo;
                let userPropertyValue = (this.item as UserInfo)[userPropertyIdentifier];

                if (userPropertyIdentifier === UserKeys.Roles && isArrayOfType(userPropertyValue, isString)) {
                    userPropertyValue = userPropertyValue.sort(stringsCaseInsensitiveLocalCompare);
                }

                // TODO available this.propertyDescriptors to use instead
                const userDescriptionListItem = await this.provisioningDescriptionService.createUserDescription(userPropertyValue, descriptor, this.item as UserInfo, this.userClaims);

                return { type: 'description', ...userDescriptionListItem };
            }

            case TableSourceType.Company: {
                const companyPropertyValue = (this.item as Company)[descriptor.identifier as keyof Company];

                // TODO available this.propertyDescriptors to use instead
                return { type: 'description', ...this.provisioningDescriptionService.createCompanyDescription(companyPropertyValue, descriptor, this.item as Company) };
            }
        }
    }

    private getHrefInfo(value: string, descriptor: DataPropertyDescriptor): DescriptionListDescription[] | undefined {
        switch (descriptor.type) {
            case FieldType.Phone:
                return [{
                    href: `tel:${value}`,
                    hrefTarget: '_blank',
                }];
            case FieldType.Email:
                return [{
                    href: `mailto:${value}`,
                    hrefTarget: '_blank',
                }];
            case FieldType.Website:
                return [{
                    href: value,
                    hrefTarget: '_blank',
                }];
            default:
                return undefined;
        }
    }

    private loadTableDetailServiceData() {
        this.title = this.parent.title;
        this.fields = this.parent.fields;
        this.item = this.parent.item;
        this.itemLink = this.parent.itemLink;
        this.sourceType = this.parent.sourceType;
        this.propertyDescriptors = this.parent.propertyDescriptors;
        this.tableModules = this.parent.tableModules;
        this.detailContextProvider = this.parent.detailContextProvider;

        this.shell.setTitle(this.title);
        this.breadcrumbsService.title = this.title;
    }

}
