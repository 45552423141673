import { Provider, Type } from '@angular/core';
import { Route, RouteReuseStrategy } from '@angular/router';
import { BucketDataDescriptorAdapterLoader, BucketDataDescriptorPermissionController, CollectionDataDescriptorAdapterLoader, CollectionDataDescriptorPermissionController, CommonTranslationKey, Repository, RunSeriallyGuard, SharedTermsTranslationKey, TimezonesTranslationKey, TranslationEntry, UfBucketDataDescriptorAdapterLoader, UfCollectionDataDescriptorAdapterLoader, UfUserDataDescriptorAdapterLoader, UserDataDescriptorAdapterLoader, UserDataDescriptorPermissionController } from '@unifii/library/common';
import { FormSettings, SmartFormsTranslationKey } from '@unifii/library/smart-forms';
import { DisplayTranslationKey } from '@unifii/library/smart-forms/display';
import { InputFormSettings, InputTranslationKey } from '@unifii/library/smart-forms/input';
import { ContentClient, ContentType, PermissionAction, ProjectContentOptions, ProjectContentOptionsInterface, PublishedContent } from '@unifii/sdk';
import { UserCsvUploadType, UserProvisioningTranslationKey, userProvisioningCacheGuard } from '@unifii/user-provisioning';

import { capacitorIdentityGuard } from 'capacitor/capacitor-identity-guard';
import { DeviceService } from 'capacitor/device.service';
import { Config, Environment } from 'config';
import { SignInHistoryComponent } from 'discover/components/sign-in/sign-in-history.component';
import { SignInComponent } from 'discover/components/sign-in/sign-in.component';
import { AuthorizePath, CompleteRegistrationPath, IdentityPath, PasswordChangePath, ProjectSelectionPath, SSOPath, SignInPath, TenantSelectionPath, UnifiiLoginPath, UnifiiPasswordChangePath, UserAccessRootPath } from 'discover/discover-constants';
import { DiscoverContent } from 'discover/discover-content.service';
import { DiscoverContext } from 'discover/discover-context';
import { DiscoverOptions } from 'discover/discover-options';
import { DiscoverModule } from 'discover/discover.module';
import { DiscoverTranslationKey, IOSPermissionKey } from 'discover/discover.tk';
import { MainComponent } from 'discover/main.component';
import { offlineContentGuard } from 'discover/offline/offline-content-guard';
import { OfflineFormComponent } from 'discover/offline-forms/offline-form.component';
import { OfflineFormsListComponent } from 'discover/offline-forms/offline-forms-list.component';
import { projectLanguageGuard } from 'discover/project-language-guard';
import { RiskMatrixComponent } from 'discover/reports/risk-matrix/risk-matrix.component';
import { anonymousGuard } from 'discover/user-access/anonymous-guard';
import { AuthorizeComponent } from 'discover/user-access/authorize.component';
import { companyGuard } from 'discover/user-access/company-guard';
import { CompleteRegistrationComponent } from 'discover/user-access/complete-registration.component';
import { LoginFormComponent } from 'discover/user-access/login/login-form.component';
import { LoginIdentityHoldComponent } from 'discover/user-access/login/login-identity-hold.component';
import { LoginComponent } from 'discover/user-access/login/login.component';
import { SSOComponent } from 'discover/user-access/login/sso.component';
import { PasswordChangeComponent } from 'discover/user-access/password-change.component';
import { projectSelectorGuard } from 'discover/user-access/project-selector-guard';
import { RequestPasswordChangeComponent } from 'discover/user-access/request-password-change.component';
import { savedUsersGuard } from 'discover/user-access/saved-users-guard';
import { SelectProjectComponent } from 'discover/user-access/select-project.component';
import { tenantGuard } from 'discover/user-access/tenant-guard';
import { TenantSelectorComponent } from 'discover/user-access/tenant-selector.component';
import { UserAccessComponent } from 'discover/user-access/user-access.component';
import { userAndProjectGuard } from 'discover/user-and-project-guard';
import { UserCreateComponent, UserInputComponent, UserUploadCsvComponent } from 'discover/user-management';
import { ContentNodeComponent } from 'shell/content/content-node.component';
import { DiscoverContentType } from 'shell/content/content-types';
import { structureGuard } from 'shell/nav/structure-guard';
import { OfflineContent } from 'shell/offline/offline-content.service';
import { ShellRouteReuseStrategy } from 'shell/route-reuse-strategy';
import { ContentDetails } from 'shell/services/content-details';
import { editedDataGuard } from 'shell/services/edited-data-guard';
import { ShellDataDescriptorPermissionController } from 'shell/services/shell-data-descriptor-permission-ctrl';
import { CreateUserPath, FormDataPath, InviteUserPath, NewItemPath, PagePath, UserInfoPath, UserProfilePath } from 'shell/shell-constants';
import { ComponentTitleRouteData } from 'shell/shell-model';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TableFilterEntryFactory } from 'shell/table/table-filter-entry-factory';
import { TableDetailComponent } from 'shell/table-detail/table-detail.component';

/** App Languages
 *  min 1 and first one is considered app default language
 */
export const languages: string[] = ['en', 'it', 'ru', 'pt-BR'];

export const translationEntries: TranslationEntry[] = [
    { identifier: 'library-shared-terms', dictionary: SharedTermsTranslationKey },
    { identifier: 'library-common', dictionary: CommonTranslationKey },
    { identifier: 'library-smart-forms', dictionary: SmartFormsTranslationKey },
    { identifier: 'library-input', dictionary: InputTranslationKey },
    { identifier: 'library-display', dictionary: DisplayTranslationKey },
    { identifier: 'library-user-provisioning', dictionary: UserProvisioningTranslationKey },
    { identifier: 'timezones', dictionary: TimezonesTranslationKey },
    { identifier: 'discover-shell', dictionary: ShellTranslationKey },
    { identifier: 'discover-discover', dictionary: DiscoverTranslationKey },
    { identifier: 'ios-permissions', dictionary: IOSPermissionKey },
];

export const createConfig = (env: Config, context: DiscoverContext, repository: Repository, device: DeviceService) =>
    new DiscoverOptions(env, context, repository, device);

export const createContent = (context: DiscoverContext, content: PublishedContent, offlineContent: OfflineContent): PublishedContent =>
    new DiscoverContent(content, offlineContent, context);

export const createProjectOptions = (config: Config): ProjectContentOptionsInterface =>
    config.unifii;

/** App Main Module */
export const modules: Type<any>[] = [DiscoverModule];

/** App Module providers, use for app level services & overrides */
export const providers: Provider[] = [
    { provide: Config, useFactory: createConfig, deps: [Environment, DiscoverContext, Repository, DeviceService] },
    { provide: ProjectContentOptions, useFactory: createProjectOptions, deps: [Config] },
    OfflineContent,
    { provide: FormSettings, useClass: InputFormSettings },
    { provide: PublishedContent, useFactory: createContent, deps: [DiscoverContext, ContentClient, OfflineContent] },
    ContentDetails,
    { provide: UserDataDescriptorAdapterLoader, useClass: UfUserDataDescriptorAdapterLoader },
    { provide: UserDataDescriptorPermissionController, useClass: ShellDataDescriptorPermissionController },
    { provide: BucketDataDescriptorAdapterLoader, useClass: UfBucketDataDescriptorAdapterLoader },
    { provide: BucketDataDescriptorPermissionController, useClass: ShellDataDescriptorPermissionController },
    { provide: CollectionDataDescriptorAdapterLoader, useClass: UfCollectionDataDescriptorAdapterLoader },
    { provide: CollectionDataDescriptorPermissionController, useClass: ShellDataDescriptorPermissionController },
    TableFilterEntryFactory,
];

export const routeReuseStrategy: Type<RouteReuseStrategy> = ShellRouteReuseStrategy;

/** Main Component Route Config */
export const mainConfig: Route = {
    component: MainComponent,
    canActivate: [RunSeriallyGuard],
    data: { guards: [
        capacitorIdentityGuard,
        tenantGuard,
        userAndProjectGuard,
        companyGuard,
        projectLanguageGuard,
        offlineContentGuard,
        structureGuard,
        userProvisioningCacheGuard,
    ] },
};

/** Anonymous routes, same level as MainComponent */
export const rootRoutes: Route[] = [
    { path: AuthorizePath, component: AuthorizeComponent },
    { path: CompleteRegistrationPath, component: CompleteRegistrationComponent },
    { path: PasswordChangePath, component: PasswordChangeComponent },
    {
        path: UserAccessRootPath, component: UserAccessComponent, children: [
            { path: '', component: LoginComponent, canActivate: [RunSeriallyGuard], data: { guards: [anonymousGuard, tenantGuard, savedUsersGuard] } },
            { path: UnifiiLoginPath, component: LoginFormComponent, canActivate: [anonymousGuard, tenantGuard] },
            { path: TenantSelectionPath, component: TenantSelectorComponent, canActivate: [anonymousGuard] },
            { path: ProjectSelectionPath, component: SelectProjectComponent, canActivate: [tenantGuard, projectSelectorGuard] },
            { path: UnifiiPasswordChangePath, component: RequestPasswordChangeComponent, canActivate: [anonymousGuard, tenantGuard] },
            { path: SSOPath, component: SSOComponent, canActivate: [tenantGuard] },
            { path: IdentityPath, component: LoginIdentityHoldComponent },
        ],
    },
];

/** Children of MainComponent route, tenant, authentication, project is loaded */
export const mainRoutes: Route[] = [
    { path: 'offline-forms', component: OfflineFormsListComponent, data: { titleTranslationKey: DiscoverTranslationKey.OfflineFormsTitle } as ComponentTitleRouteData },
    { path: 'offline-forms/:key', component: OfflineFormComponent },
    {
        path: SignInPath, component: SignInHistoryComponent, children: [
            { path: `:id`, component: SignInComponent },
        ],
    },
];

/** Provisioning Routes */
export const userInviteRoute: Route = {
    path: InviteUserPath, component: UserCreateComponent, data: { titleTranslationKey: DiscoverTranslationKey.UserInviteTitle, action: PermissionAction.Invite } as ComponentTitleRouteData, children: [
        { path: '', pathMatch: 'full', redirectTo: 'input', data: { action: PermissionAction.Invite } },
        { path: 'input', component: UserInputComponent, canDeactivate: [editedDataGuard], data: { action: PermissionAction.Invite } },
        { path: 'upload-basic-csv', component: UserUploadCsvComponent, canDeactivate: [editedDataGuard], data: { action: PermissionAction.Invite, uploadType: UserCsvUploadType.BASIC } },
        { path: 'upload-advanced-csv', component: UserUploadCsvComponent, canDeactivate: [editedDataGuard], data: { action: PermissionAction.Invite, uploadType: UserCsvUploadType.ADVANCED } },
    ],
};

export const userCreateRoute: Route = {
    path: CreateUserPath, component: UserCreateComponent, data: { titleTranslationKey: DiscoverTranslationKey.UserCreateTitle, action: PermissionAction.Add } as ComponentTitleRouteData, children: [
        { path: '', pathMatch: 'full', redirectTo: 'input', data: { action: PermissionAction.Add } },
        { path: 'input', component: UserInputComponent, canDeactivate: [editedDataGuard], data: { action: PermissionAction.Add } },
        { path: 'upload-basic-csv', component: UserUploadCsvComponent, canDeactivate: [editedDataGuard], data: { action: PermissionAction.Add, uploadType: UserCsvUploadType.BASIC } },
        { path: 'upload-advanced-csv', component: UserUploadCsvComponent, canDeactivate: [editedDataGuard], data: { action: PermissionAction.Add, uploadType: UserCsvUploadType.ADVANCED } },
    ],
};

/**
 * @description
 * ContentRoutes refer to routes that generate content that match
 * predefined discover content from either StructureNodesTypes, ContentType or DiscoverContentTypes.
 * table | view | page | collection | bucket | form | detail | user | company
 *
 * Content is created using the ContentNodeComponent which provides a
 * centralized place where data is loaded, permissions are validated, loading state is handled and errors are caught and display.
 *
 * All content will load based on content detection from the identifier
 * expect forms which don't may not have an identifier available in that case a custom path is created and the content is set via
 * the a data attribute.
 */

export const contentRoutes: Route[] = [
    { path: UserInfoPath, component: ContentNodeComponent, data: { contentType: DiscoverContentType.User } },
    { path: UserProfilePath, component: ContentNodeComponent, canDeactivate: [editedDataGuard], data: { contentType: DiscoverContentType.UserProfile } },
    { path: `${FormDataPath}/:bucket/:id`, component: ContentNodeComponent, data: { contentType: ContentType.Form } },
    { path: `${PagePath}/:id`, component: ContentNodeComponent, data: { contentType: ContentType.Page } }, // TODO - Remove Legacy URL
    {
        path: ':identifier', component: ContentNodeComponent, canDeactivate: [editedDataGuard], children: [ // Form, Table, Page, View, Collection
            userCreateRoute, userInviteRoute,
            { path: NewItemPath, component: ContentNodeComponent, canDeactivate: [editedDataGuard] }, // Company, Form
            {
                path: ':id', component: ContentNodeComponent, canDeactivate: [editedDataGuard], children: [ // FormData, CollectionItem
                    { path: '', component: TableDetailComponent },
                    { path: ':identifier', component: ContentNodeComponent, data: { contentType: ContentType.Table } }, // Table
                ],
            },
        ],
    }];

/** Children of 'node/id/custom/' route */
export const customRoutes: Route[] = [
    {
        path: 'sign-in', component: SignInHistoryComponent, children: [
            { path: ':id', component: SignInComponent },
        ],
    },
    // Custom Reports
    {
        path: 'custom-reports-risk-matrix', component: RiskMatrixComponent, data: { title: 'Risk Matrix' },
    },
];

// TODO setup custom renderers for collections and views.
