import { Injectable, inject } from '@angular/core';
import { DescriptionListDescription, FieldDescriptionService, FormDefinitionMetadataIdentifiers, RuntimeField, Scope } from '@unifii/library/common';
import { DataSourceType, PermissionAction, isStringNotEmpty } from '@unifii/sdk';

import { DiscoverContext } from 'discover/discover-context';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { FormDataPath } from 'shell/shell-constants';

/**
 * DiscoverFieldDescriptionService provides a way for discover to
 * extend the library field description service and modify or add values that are useful to the app context
 * eg: Add router link to app content
 */
@Injectable()
export class DiscoverFieldDescriptionService extends FieldDescriptionService {

    private auth = inject(Authentication);
    private context = inject(DiscoverContext);

    override transform(v: any, field: RuntimeField, scope?: Scope, format?: string): DescriptionListDescription[] {
        /**
         * Access attempt to access child properties if value is undefined and scope is defined
         */
        if (scope && v == null && field.identifier?.includes('.')) {
            v = this.childValueAccessor(field.identifier, scope);
        }

        const description = super.transform(v, field, undefined, format);
        const routerLink = this.getRouterLink(field, scope ?? {}, this.projectId);

        if (routerLink != null && description[0] != null) {
            description[0].routerLink = routerLink;
        }

        return description;
    }

    /**
     ** This method creates a router link to specific form when identifier is _seqId
     */
    private getRouterLink({ identifier, parent }: RuntimeField, { _id }: Scope, projectId: string): any[] | undefined {

        if (!parent?.sourceConfig || parent.sourceConfig.type !== DataSourceType.Bucket || !isStringNotEmpty(_id) || identifier !== FormDefinitionMetadataIdentifiers.SeqId) {
            return undefined;
        }

        const bucketId = parent.sourceConfig.id;
        const accessPath = PermissionsFunctions.getBucketDocumentPath(projectId, bucketId, _id);
        const allowed = this.auth.getGrantedInfoWithoutCondition(accessPath, PermissionAction.Read).granted;

        if (allowed) {
            // datasource id is bucket, _id is formID
            return [`/`, FormDataPath, bucketId, _id];
        }

        return undefined;
    }

    /**
     * Recursively attempts to access child properties values
     */
    private childValueAccessor(property: string, scope: Scope): any {
        return property.split('.').reduce((s, prop) => this.accessUnknownMember(prop, s), scope);
    }

    private get projectId(): string {
        if (this.context.project) {
            return this.context.project.id;
        }

        return '';
    }

    private accessUnknownMember(prop: string, scope: Scope): any {
        try {
            return scope[prop];
        } catch (e) { /**/ }
    }

}
