import { Component, Input, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, FileUploader, ModalService } from '@unifii/library/common';
import { FormSettings } from '@unifii/library/smart-forms';
import { ContentLinkFormData, LinkContentType } from '@unifii/sdk';

import { PdfViewerModalComponent } from 'shell/common/modals/pdf-viewer-modal.component';
import { ShellFormService } from 'shell/form/shell-form.service';

enum LinkType {
    RouterLink = 'routerLink',
    Href = 'href',
    AttachmentLink = 'attachmentLink',
    PdfViewer = 'pdfViewer'
}

@Component({
    selector: 'us-content-link-url',
    templateUrl: './content-link-url.html',
    styleUrls: ['./content-link-url.less'],
    providers: [
        {
            provide: FileUploader, useFactory: (formSettings: FormSettings) => formSettings.uploader, deps: [FormSettings],
        },
    ],
})
export class ContentLinkUrlComponent implements OnInit {

    @Input({ required: true }) content: ContentLinkFormData | undefined;
    @Input() showType: boolean | null;

    protected readonly linkTypes = LinkType;
    protected linkType: LinkType;
    protected url: string | any[];
    protected contentTypeLabel: string;

    private modalService = inject(ModalService);
    private translateService = inject(TranslateService);
    private formSettings = inject(FormSettings);
    private formService = inject(ShellFormService);

    ngOnInit() {
        if (!this.content) {
            return;
        }

        this.updateDisplayInfo(this.content);
    }

    protected async openPDFViewer(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        if (!this.content) {
            return;
        }

        // TODO Review - FileUploader require a FormDataId to generate a file URL
        const uploader = this.formSettings.uploader ?? this.formService.getFileUploader('');
        const url = await uploader.getUrl(this.content.id);

        void this.modalService.openLarge(PdfViewerModalComponent, { title: this.content.label, url });
    }

    private updateDisplayInfo(contentLink: ContentLinkFormData) {
        switch (contentLink.type) {
            // TODO - Asset
            // case LinkContentType.Asset:
            //     this.linkType = contentLink?.useViewer ? LinkType.PdfViewer : LinkType.AttachmentLink;
            //     this.url = contentLink.id;
            //     this.contentTypeLabel = this.translate.instant(CommonTranslationKey.ContentTypeAssetLabel);
            //     break;
            case LinkContentType.Attachment:
                this.linkType = contentLink?.useViewer ? LinkType.PdfViewer : LinkType.AttachmentLink;
                this.url = contentLink.id;
                this.contentTypeLabel = this.translateService.instant(CommonTranslationKey.ContentTypeAttachmentLabel) as string;
                break;
            case LinkContentType.Form:
                this.linkType = LinkType.RouterLink;
                this.url = ['/', contentLink.id];
                this.contentTypeLabel = this.translateService.instant(CommonTranslationKey.ContentTypeFormLabel) as string;
                break;
            case LinkContentType.Page:
                this.linkType = LinkType.RouterLink;
                this.url = ['/', contentLink.id];
                this.contentTypeLabel = this.translateService.instant(CommonTranslationKey.ContentTypePageLabel) as string;
                break;
            case LinkContentType.Url:
                this.linkType = LinkType.Href;
                this.url = contentLink.id;
                this.contentTypeLabel = this.translateService.instant(CommonTranslationKey.ContentTypeUrlLabel) as string;
                break;
        }
    }

}
