import { AppContext, Context, ContextProvider, FnsDatetime } from '@unifii/library/common';
import { Company, FormData, UserInfo } from '@unifii/sdk';
import { format, set } from 'date-fns';

export interface TableDetailContext extends AppContext, Context {
    detail?: FormData | UserInfo;
}

export class TableDetailContextProvider implements ContextProvider {

    constructor(
        private contextProvider: ContextProvider,
        private detail: FormData | UserInfo | Company,
    ) { }

    get(): TableDetailContext {

        const appContext: AppContext = this.contextProvider.get();
        const now = format(set(new Date(), { seconds: 0 }), FnsDatetime);

        return Object.assign({ self: null, root: {}, now }, appContext, {
            detail: this.detail,
        });
    }

}
