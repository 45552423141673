import { Directive, HostBinding, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
    selector: '[ufMasterDetail]',
})
export class MasterDetailDirective implements OnDestroy {

    @HostBinding('class.master-detail') isDetails: boolean;

    private subscriptions = new Subscription();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
    ) {

        this.subscriptions.add(
            this.router.events.pipe(filter((e) => e instanceof NavigationEnd))
                .subscribe(() => {
                    this.isDetails = !!this.route.children.length;
                }),
        );

        this.isDetails = !!this.route.children.length;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
