import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ContextProvider, ExpressionParser } from '@unifii/library/common';
import { Dictionary, StructureNode, StructureNodeArg, TokenStorage, TokenStorageInterface } from '@unifii/sdk';

import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { NavigationService } from 'shell/nav/navigation.service';

@Component({
    templateUrl: './iframe.html',
    styleUrls: ['./iframe.less'],
})
export class IFrameComponent implements OnInit {

    error: AppError;
    url: SafeResourceUrl | null;

    constructor(
        private navService: NavigationService,
        private sanitizer: DomSanitizer,
        private expressionParser: ExpressionParser,
        @Inject(ContextProvider) private contextProvider: ContextProvider,
        @Inject(TokenStorage) private tokenStorage: TokenStorageInterface,
        private errorService: ErrorService,
    ) { }

    ngOnInit() {

        try {
            const node = this.navService.current as StructureNode;

            this.url = this.createUrl(node);
        } catch (error) {
            this.error = error as AppError;
        }
    }

    private createUrl(node: StructureNode) {

        if (!node.url) {
            throw this.errorService.createNotFoundError('URL');
        }

        let url = node.url;
        const params = this.createQueryParams(node.args);

        if (params != null) {
            url += '?' + this.stringfyParams(params);
        }

        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    private createQueryParams(args: StructureNodeArg[] | undefined): Record<string, any> | undefined {

        return args?.reduce((result, arg) => {

            let { value } = arg;

            if (arg.isExpression) {
                value = this.parseExpression(value);
            }

            if (value !== undefined) {
                result[arg.key] = value;
            }

            return result;
        }, {} as Dictionary<string>);
    }

    private stringfyParams(params: Record<string, any>): string {

        const searchParams = new URLSearchParams(params as any);

        return searchParams.toString();
    }

    private parseExpression(value: string): string | undefined {
        // Convert expressions to values with app context
        const context = this.contextProvider.get();

        // Access token added to context
        context.accessToken = this.tokenStorage.token;

        return this.expressionParser.resolve(value, context as any);
    }

}
