<uf-panel class="container bg-gray">
    <uf-form *ngIf="definition && formData" [definition]="definition" [config]="formConfig" [(formData)]="formData"
        (submit)="save($event)" (cancelClick)="back()" (edited)="edited = $event">
        <div header class="uf-app-bar transparent">
            <a (click)="back()" class="uf-button tertiary left align-left">
                <uf-icon name="arrowBack" />
                {{sharedTK.ActionBack | translate}}
            </a>
        </div>
        <footer footer>
            <uf-description-list *ngIf="definition.version" class="pad small small-label">
                <dt>{{commonTK.FormMetadataFieldDefinitionVersionLabel | translate }}:</dt>
                <dd>{{definition.version}}</dd>
            </uf-description-list>
        </footer>
    </uf-form>
</uf-panel>