import { Component, HostBinding, Input, inject } from '@angular/core';

import { Config, Environment } from 'config';
import { grow } from 'shell/core/drawer-animations';
import { DrawerComponent } from 'shell/core/drawer.component';
import { MenuDrawerComponent } from 'shell/core/menu-drawer.component';
import { Mode, ShellService } from 'shell/core/shell.service';
import { NavigationService } from 'shell/nav/navigation.service';

@Component({
	selector: 'us-header',
	templateUrl: './header.html',
	styleUrls: ['./header.less'],
	animations: [grow],
})
export class HeaderComponent {

    @Input() leftDrawer: DrawerComponent;
    @Input() rightDrawer: DrawerComponent;
    @Input() menuDrawer: MenuDrawerComponent;
    @Input() title: string;

    @HostBinding('class.preview') get previewClass() { return this.shell.mode === Mode.Preview; }
    @HostBinding('class.uf-app-bar') appBar = true;

    protected readonly previewMode = Mode.Preview;
    protected disablePreviewToggle: boolean;

    protected shell = inject(ShellService);
    private env = inject<Config>(Environment);
    private navigationService = inject(NavigationService);

    constructor() {
    	this.disablePreviewToggle = this.env.unifii.preview === true;
    }

    protected back() {
    	this.navigationService.back();
    }

}
