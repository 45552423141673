<div class="bg-img"></div>

<uf-panel class="container">

    <uf-progress class="large" />

    <div [class.inProgress]="inProgress" class="box pad-sides pad-top">

        <div class="navigation row">
            <div class="grow">
                <button *ngIf="backButtonRoute" [disabled]="inProgress" [routerLink]="[backButtonRoute]"
                    [title]="sharedTermsTK.ActionBack | translate" type="button" class="uf-action small">
                    <uf-icon name="arrowBack" />
                </button>
            </div>
            <div class="col shrink">
                <div class="contact-info">
                    <button [title]="discoverTK.ContactInfoLabel | translate" [disabled]="inProgress"
                        (click)="showContactInfo()" type="button" class="uf-action small">
                        <uf-icon name="query" />
                    </button>
                </div>
                <ng-template [usFeatureFlag]="'translations'">
                    <div *ngIf="translations.languages.length > 1" [title]="discoverTK.LanguageLabel | translate"
                        (click)="showChangeLanguage()" class="language">
                        <img src="assets/svg/language-{{translations.currentLanguage}}.svg">
                    </div>
                </ng-template>
            </div>
        </div>

        <div class="logo-wrapper">
            <img src="{{productLogo}}?h=100" class="logo" alt="Unifii" />
        </div>

        <div class="content">
            <ng-content select="[content]"></ng-content>
        </div>

        <div class="footer body-copy small">
            <p *ngIf="config.unifii.termsOfUseMessageTk" class="terms-of-use-message">
                <span>{{ config.unifii.termsOfUseMessageTk | translate}}</span>
            </p>
            <div class="row">
                <div class="grow product-information">
                    <!--Environment privacy policy (no project info available at this stage) -->
                    <p *ngIf="config.unifii.privacyPolicyUrl" class="x-small">
                        <a href="{{config.unifii.privacyPolicyUrl}}" ufTarget="_blank">
                            {{ config.unifii.privacyPolicyLabelTk || discoverTK.PrivacyPolicyLabel | translate}}
                        </a>
                    </p>
                    <uf-description-list [items]="versionInfo" class="stacked small small-label" />
                </div>
                <div class="right row">
                    <a class="col" href="https://www.unifii.com.au" ufTarget="_blank">
                        <img class="supplier-logo" src="assets/svg/product-logo-secondary.svg"
                            alt="Powered by Unifii" />
                    </a>
                </div>
            </div>
        </div>
    </div>

</uf-panel>