<uf-panel class="content-pane content scrollable bg-gray">
    <div *ngIf="!uploadResult && form" [formGroup]="form" class="uf-container-lg bg-solid">
        <div class="uf-grid pad-top row-gap-lg">
            <div *ngIf="usersControl" class="uf-box col-12">
                <div class="uf-app-bar accent medium">
                    <div class="title">{{ discoverTK.UsersLabel | translate }}</div>
                </div>
                <div class="uf-grid pad">

                    <!-- Mobile View -->
                    <div *ngIf="usersControl.value.length;"
                        class="col-12 col space-children margin--top user-card-display">
                        <div *ngFor="let userControl of usersControl.controls; let i = index" class="uf-card">
                            <div class="pad">
                                <button [title]="sharedTermsTK.ActionRemove | translate" (click)="removeUser(i)"
                                    type="button" class="uf-action tertiary small float-right">
                                    <uf-icon name="delete" />
                                </button>
                                <button [title]="sharedTermsTK.ActionEdit | translate" (click)="editUser(i)"
                                    type="button" class="uf-action tertiary small float-right">
                                    <uf-icon name="edit" />
                                </button>
                                <uf-description-list class="small-label">
                                    <ng-template [ngIf]="userControl.get(userInfoKeys.Username)?.enabled">
                                        <dt>{{ commonTK.UsernameLabel | translate }}</dt>
                                        <dd>{{ userControl.get(userInfoKeys.Username)?.value }}</dd>
                                    </ng-template>

                                    <ng-template [ngIf]="userControl.get(userInfoKeys.FirstName)?.enabled">
                                        <dt>{{ commonTK.FirstNameLabel | translate }}</dt>
                                        <dd>{{ userControl.get(userInfoKeys.FirstName)?.value }}</dd>
                                    </ng-template>

                                    <ng-template [ngIf]="userControl.get(userInfoKeys.LastName)?.enabled">
                                        <dt>{{ commonTK.LastNameLabel | translate }}</dt>
                                        <dd>{{ userControl.get(userInfoKeys.LastName)?.value }}</dd>
                                    </ng-template>

                                    <ng-template [ngIf]="userControl.get(userInfoKeys.Email)?.enabled">
                                        <dt>{{ commonTK.EmailLabel | translate }}</dt>
                                        <dd>{{ userControl.get(userInfoKeys.Email)?.value }}</dd>
                                    </ng-template>

                                    <ng-template [ngIf]="userControl.get(userInfoKeys.Phone)?.enabled">
                                        <dt>{{ commonTK.PhoneLabel | translate }}</dt>
                                        <dd>{{ userControl.get(userInfoKeys.Phone)?.value }}</dd>
                                    </ng-template>
                                </uf-description-list>
                            </div>
                        </div>
                    </div>
                    <!-- Desktop view -->
                    <div *ngIf="usersControl.value.length;" class="col-12 user-table-display">
                        <table class="uf-table break-word">
                            <thead>
                                <tr>
                                    <th *ngIf="usersControl.at(0).get(userInfoKeys.Username)?.enabled">{{
                                        commonTK.UsernameLabel | translate }}</th>
                                    <th *ngIf="usersControl.at(0).get(userInfoKeys.FirstName)?.enabled">{{
                                        commonTK.FirstNameLabel | translate }}</th>
                                    <th *ngIf="usersControl.at(0).get(userInfoKeys.LastName)?.enabled">{{
                                        commonTK.LastNameLabel | translate }}</th>
                                    <th *ngIf="usersControl.at(0).get(userInfoKeys.Email)?.enabled">{{
                                        commonTK.EmailLabel | translate }}</th>
                                    <th *ngIf="usersControl.at(0).get(userInfoKeys.Phone)?.enabled">{{
                                        commonTK.PhoneLabel | translate }}</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let userControl of usersControl.controls; let i = index">
                                    <td *ngIf="userControl.get(userInfoKeys.Username)?.enabled;">
                                        {{ userControl.get(userInfoKeys.Username)?.value }}
                                    </td>
                                    <td *ngIf="userControl.get(userInfoKeys.FirstName)?.enabled;">
                                        {{ userControl.get(userInfoKeys.FirstName)?.value }}
                                    </td>
                                    <td *ngIf="userControl.get(userInfoKeys.LastName)?.enabled;">
                                        {{ userControl.get(userInfoKeys.LastName)?.value }}
                                    </td>
                                    <td *ngIf="userControl.get(userInfoKeys.Email)?.enabled;">
                                        {{ userControl.get(userInfoKeys.Email)?.value }}
                                    </td>
                                    <td *ngIf="userControl.get(userInfoKeys.Phone)?.enabled;">
                                        {{ userControl.get(userInfoKeys.Phone)?.value }}
                                    </td>
                                    <td class="pad-right">
                                        <div class="row space-children">
                                            <button [title]="sharedTermsTK.ActionEdit | translate" (click)="editUser(i)"
                                                type="button" class="uf-action tertiary small right">
                                                <uf-icon name="edit" />
                                            </button>
                                            <button [title]="sharedTermsTK.ActionRemove | translate"
                                                (click)="removeUser(i)" type="button"
                                                class="uf-action tertiary small">
                                                <uf-icon name="delete" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 row center-all">
                        <uf-error [control]="usersControl" />
                        <button (click)="addUser()" type="button" class="uf-button right small">
                            {{ discoverTK.UserAddAction | translate }}
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="changePasswordOnNextLoginControl && changePasswordOnNextLoginControl.enabled"
                class="col-12 uf-box">
                <div class="uf-app-bar accent medium">
                    <div class="title">{{ commonTK.PasswordLabel | translate }}</div>
                </div>
                <div class="uf-grid pad">
                    <uf-checkbox [label]="discoverTK.UserCreateNewPasswordLabel | translate"
                        [control]="changePasswordOnNextLoginControl" class="col-12" />
                </div>
            </div>

            <div *ngIf="companyControl && companyControl.enabled" class="col-12 uf-box">
                <div class="uf-app-bar accent medium">
                    <div class="title">{{ commonTK.CompanyLabel | translate }}</div>
                </div>
                <div class="pad">
                    <uf-company-input [control]="companyControl" [editableCompanies]="allowedCompanies"
                        class="col-12 col-md-6" />
                </div>
            </div>

            <div *ngIf="unitsControl && unitsControl.enabled" class="uf-box col-12 gap-top-sm">
                <div class="uf-app-bar flat medium accent">
                    <div class="title">{{ commonTK.HierarchyLabel | translate }}</div>
                </div>
                <div class="uf-grid pad-bottom">
                    <uf-hierarchy-input [control]="unitsControl" class="col-12" />
                </div>
            </div>

            <div *ngIf="rolesControl && rolesControl.enabled" class="uf-box col-12">
                <div class="uf-app-bar medium accent">
                    <div class="title">{{ commonTK.RolesLabel | translate }}</div>
                </div>
                <div class="pad">
                    <uf-role-input [control]="rolesControl" [editableRoles]="allowedRoles" />
                </div>
            </div>

            <div *ngIf="showClaimsSection && claimsControl" class="col-12 uf-box">
                <div class="uf-app-bar accent medium">
                    <div class="title">{{ commonTK.ClaimsLabel | translate }}</div>
                </div>
                <uf-claim-editor [control]="claimsControl" [editableClaimsValues]="allowedClaimsValues"
                    [width]="fieldWidth.Half" class="col-12 pad-sm-bottom" />
            </div>

            <div class="col-12 row">
                <button (click)="back()" type="button" class="uf-button tertiary left">
                    {{sharedTermsTK.ActionCancel | translate}}
                </button>
                <button (click)=" save()" type="button" class="uf-button primary">
                    {{ saveButtonLabel }}
                </button>
            </div>

        </div>
    </div>
    <ud-user-upload-result *ngIf="uploadResult" [uploadResult]="uploadResult" [users]="usersControl?.value"
        (resolved)="back(true)" />
</uf-panel>