import { Component, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, SharedTermsTranslationKey, UfControl, ValidatorFunctions } from '@unifii/library/common';
import { Subscription } from 'rxjs';

import { DeviceRegistrationService } from 'capacitor/device-registration.service';
import { Config, Environment } from 'config';
import { UserAccessRootPath } from 'discover/discover-constants';
import { AppError } from 'shell/errors/errors';
import { TenantSettingsService } from 'shell/services/tenant-settings.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

@Component({
    selector: 'ud-tenant-selector',
    templateUrl: './tenant-selector.html',
})
export class TenantSelectorComponent implements OnDestroy {

    readonly sharedTermsTK = SharedTermsTranslationKey;
    readonly commonTK = CommonTranslationKey;

    tenant: string;
    control: UfControl;

    private subscriptions = new Subscription();

    constructor(
        @Inject(Config) private config: Config,
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private accessManager: UserAccessManager,
        @Inject(Environment) private env: Config,
        private tenantSettings: TenantSettingsService,
        private deviceRegistrationService: DeviceRegistrationService,
    ) {

        // it is unlikely but if someone hacks the url to get to this component reroute to main
        if (this.env.unifii.tenant) {
            this.router.navigate(['/', UserAccessRootPath]);
        }

        this.control = this.createControl();

        this.subscriptions.add(this.route.params.subscribe((params) => {
            if (params.tenant) {
                this.tenant = params.tenant.toLowerCase();
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    async setTenant(): Promise<void> {

        this.accessManager.showError(null);

        /**
         * Extra guard added for Mobile Safari UI/WKWebView
         * tenant is evaluated as undefined guessing it's caused timing issue with validators not firing fast enough
         * sentry error: https://sentry.unifii.dev/organizations/unifii-pty-ltd/issues/258/events/?environment=prod&project=7
         */
        if (this.control.invalid || !this.tenant) {
            this.control.setSubmitted();

            return;
        }
        try {
            await this.tenantSettings.setTenant(this.tenant.toLowerCase());
            this.deviceRegistrationService.register();
            this.router.navigate(['/', UserAccessRootPath]);
        } catch (e) {
            this.accessManager.showError(e as AppError);
        }
    }

    private createControl(): UfControl {

        return new UfControl([
            ValidatorFunctions.custom((v) => this.config.unifii.tenant != null || (v != null && v !== ''), this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired)),
            ValidatorFunctions.pattern(/^[A-Za-z0-9.-]+$/, this.translate.instant(SharedTermsTranslationKey.ValidatorInvalidCharacters)),
            ValidatorFunctions.custom((v) => !/\s/.test(v), this.translate.instant(SharedTermsTranslationKey.ValidatorInvalidSpaces)),
        ]);
    }

}
