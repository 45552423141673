<div class="uf-container-lg bg-solid">
    <div class="uf-grid pad-top row-gap-lg">
        <div class="uf-box col-12">
            <div class="uf-app-bar accent medium">
                <div class="title">{{ discoverTK.UsersUploadResultStatusTitle | translate }}</div>
            </div>
            <div class="uf-grid pad">
                <div class="col-12">
                    <uf-description-list [items]="uploadStatus" class="small-label" />
                </div>
            </div>
        </div>
        <div *ngIf="form?.controls?.length || uploadResult.errors.length" class="col-12 uf-box">
            <div class="uf-app-bar accent medium">
                <div class="title">{{ discoverTK.UsersUploadResultFailedEntriesTitle | translate }}</div>
            </div>
            <div class="uf-grid">
                <div class="col-12 pad-bottom">
                    <p *ngIf="form.controls.length" class="small pad-sides">
                        {{ discoverTK.UsersUploadResultFailedEntriesMessage | translate }}
                    </p>
                    <div *ngIf="uploadResult.errors.length && !form.controls.length" class="pad-sides pad-top">
                        <table class="uf-table break-word">
                            <thead>
                                <tr>
                                    <th *ngIf="csvUpload">
                                        {{ discoverTK.UsersUploadResultLineNumberLabel | translate }}</th>
                                    <th *ngIf="!csvUpload">
                                        {{ discoverTK.UsersUploadResultUserNumberLabel | translate }}</th>
                                    <th>{{ sharedTermsTK.Error | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let error of uploadResult.errors; let i = index">
                                    <td *ngIf="csvUpload">{{ error.index + 2 }}</td>
                                    <td *ngIf="!csvUpload">{{ error.index + 1 }}</td>
                                    <td class="error-message">{{ error.errorMessage }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <ng-container *ngIf="form.controls.length">
                        <div class="col space-children margin--top user-card-display">
                            <div *ngFor="let control of form.controls; let i = index"
                                class="uf-card margin--horizontal--large">
                                <div class="pad">
                                    <button [title]="sharedTermsTK.ActionRemove | translate" (click)="removeUser(i)"
                                        type="button" class="uf-action tertiary small float-right">
                                        <uf-icon name="delete" />
                                    </button>
                                    <button [title]="sharedTermsTK.ActionEdit | translate" (click)="editUser(i)"
                                        type="button" class="uf-action tertiary small float-right">
                                        <uf-icon name="edit" />
                                    </button>
                                    <uf-description-list class="small-label">
                                        <ng-container
                                            *ngIf="context.action === actionAdd && control.get(userInfoKey.Username)?.enabled">
                                            <dt>{{ commonTK.UsernameLabel | translate }}</dt>
                                            <dd>{{ control.value?.username }}</dd>
                                        </ng-container>
                                        <ng-template [ngIf]="control.get(userInfoKey.FirstName)?.enabled">
                                            <dt>{{ commonTK.FirstNameLabel | translate }}</dt>
                                            <dd>{{ control.value?.firstName }}</dd>
                                        </ng-template>
                                        <ng-template [ngIf]="control.get(userInfoKey.LastName)?.enabled">
                                            <dt>{{ commonTK.LastNameLabel | translate }}</dt>
                                            <dd>{{ control.value?.lastName }}</dd>
                                        </ng-template>
                                        <ng-template [ngIf]="control.get(userInfoKey.Email)?.enabled">
                                            <dt>{{ commonTK.EmailLabel | translate }}</dt>
                                            <dd>{{ control.value?.email }}</dd>
                                        </ng-template>
                                        <ng-template [ngIf]="control.get(userInfoKey.Phone)?.enabled">
                                            <dt>{{ commonTK.PhoneLabel | translate }}</dt>
                                            <dd>{{ control.value?.phone }}</dd>
                                        </ng-template>
                                    </uf-description-list>
                                </div>
                            </div>
                        </div>
                        <div class="pad-sides user-table-display">
                            <table class="uf-table">
                                <thead>
                                    <tr *ngIf="form.controls[0]; let firstControl">
                                        <th *ngIf="firstControl.get(userInfoKey.Username)?.enabled">{{
                                            commonTK.UsernameLabel | translate }}</th>
                                        <th *ngIf="firstControl.get(userInfoKey.FirstName)?.enabled">{{
                                            commonTK.FirstNameLabel | translate }}</th>
                                        <th *ngIf="firstControl.get(userInfoKey.LastName)?.enabled">{{
                                            commonTK.LastNameLabel | translate }}</th>
                                        <th *ngIf="firstControl.get(userInfoKey.Email)?.enabled">{{
                                            commonTK.EmailLabel | translate }}</th>
                                        <th *ngIf="firstControl.get(userInfoKey.Phone)?.enabled">{{
                                            commonTK.PhoneLabel | translate }}</th>
                                        <th>{{ sharedTermsTK.Error | translate }}</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let control of form.controls; let i = index">
                                        <td *ngIf="control.get(userInfoKey.Username)?.enabled">{{
                                            control.value?.username }}</td>
                                        <td *ngIf="control.get(userInfoKey.FirstName)?.enabled">{{
                                            control.value?.firstName }}</td>
                                        <td *ngIf="control.get(userInfoKey.LastName)?.enabled">{{
                                            control.value?.lastName }}</td>
                                        <td *ngIf="control.get(userInfoKey.Email)?.enabled">{{ control.value?.email
                                            }}</td>
                                        <td *ngIf="control.get(userInfoKey.Phone)?.enabled">{{ control.value?.phone
                                            }}</td>
                                        <td class="error-message">{{ uploadResult.errors[i]?.errorMessage }}</td>
                                        <td class="pad-right">
                                            <div class="row space-children">
                                                <button [title]="sharedTermsTK.ActionEdit | translate"
                                                    (click)="editUser(i)" type="button"
                                                    class="uf-action tertiary small right">
                                                    <uf-icon name="edit" />
                                                </button>
                                                <button [title]="sharedTermsTK.ActionRemove | translate"
                                                    (click)="removeUser(i)" type="button"
                                                    class="uf-action tertiary small">
                                                    <uf-icon name="delete" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="col-12 row">
            <button (click)="back()" type="button" class="uf-button tertiary left">
                {{sharedTermsTK.ActionCancel | translate}}
            </button>
            <button *ngIf="form.controls.length" (click)="save()" type="button" class="uf-button primary">
                {{sharedTermsTK.ActionCreate | translate}}
            </button>
        </div>
    </div>
</div>