import { Component, Input, OnInit, inject } from '@angular/core';

import { DiscoverTranslationKey } from 'discover/discover.tk';
import { PinService } from 'discover/pin/pin-types';

@Component({
    selector: 'ud-pin-input',
    templateUrl: './pin-input.html',
})
export class PinInputComponent implements OnInit {

    @Input() pinTimeout: number;

    protected readonly discoverTK = DiscoverTranslationKey;
    protected pinService = inject(PinService);
    
    ngOnInit() {
        if (this.pinService.isEnabled()) {
            this.pinTimeout = this.pinService.getPinDetails().timeout;
        }
    }

    async resetPin() {
        await this.pinService.showPinReset();
    }

}

