import { Component, HostBinding, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, SharedTermsTranslationKey, UfControl, UfControlGroup } from '@unifii/library/common';
import { Dictionary, FieldWidth, PermissionAction } from '@unifii/sdk';
import { UserControlService, UserFieldLabelService, UserFormContext, UserKeys } from '@unifii/user-provisioning';

import { DiscoverTranslationKey } from 'discover/discover.tk';

export interface UserAddModalData {
    control: UfControlGroup;
    isEdit?: boolean;
}

@Component({
    selector: 'ud-user-add',
    templateUrl: './user-add.html',
    styleUrls: ['user-add.less'],
})
export class UserAddComponent implements Modal<UserAddModalData, UfControlGroup> {

    @HostBinding('class.uf-form-card') class = true;

    runtime = inject(ModalRuntime) as ModalRuntime<UserAddModalData, UfControlGroup>;
    data = inject(ModalData) as UserAddModalData;

    protected readonly userInfoControlKeys = UserKeys;
    protected readonly fieldWidth = FieldWidth;
    protected readonly actionAdd = PermissionAction.Add;
    protected readonly actionInvite = PermissionAction.Invite;
    protected readonly discoverTK = DiscoverTranslationKey;
    protected readonly sharedTermsTK = SharedTermsTranslationKey;

    protected userControlService = inject(UserControlService);
    protected action = inject(UserFormContext).action as PermissionAction.Add | PermissionAction.Invite;
    protected form = this.data.control;
    protected labelDictionary: Dictionary<string> = inject(UserFieldLabelService).labelDictionary;
    protected editableCompanies = this.userControlService.getEditableCompanies(undefined);
    protected editableClaimsValues = this.userControlService.getEditableClaimsValues(undefined);
    protected firstNameControl = this.form.get(UserKeys.FirstName) as UfControl | undefined;
    protected firstNameRequired = this.userControlService.isFieldRequired(UserKeys.FirstName);
    protected lastNameControl = this.form.get(UserKeys.LastName) as UfControl | undefined;
    protected lastNameRequired = this.userControlService.isFieldRequired(UserKeys.LastName);
    protected emailControl = this.form.get(UserKeys.Email) as UfControl | undefined;
    protected emailRequired = this.userControlService.isFieldRequired(UserKeys.Email);
    protected phoneControl = this.form.get(UserKeys.Phone) as UfControl | undefined;
    protected phoneRequired = this.userControlService.isFieldRequired(UserKeys.Phone);
    protected usernameControl = this.form.get(UserKeys.Username) as UfControl | undefined;
    protected usernameRequired = this.userControlService.isFieldRequired(UserKeys.Username);
    protected canChangeUsernameControl = this.form.get(UserKeys.CanChangeUsername) as UfControl | undefined;
    protected passwordControl = this.form.get(UserKeys.Password) as UfControl | undefined;
    protected passwordRequired = this.userControlService.isFieldRequired(UserKeys.Password);
    protected rolesControl = this.form.get(UserKeys.Roles) as UfControl | undefined;
    protected rolesRequired = this.userControlService.isFieldRequired(UserKeys.Roles);
    protected unitsControl = this.form.get(UserKeys.Units) as UfControl | undefined;
    protected unitsRequired = this.userControlService.isFieldRequired(UserKeys.Units);
    protected companyControl = this.form.get(UserKeys.Company) as UfControl | undefined;
    protected companyRequired = this.userControlService.isFieldRequired(UserKeys.Company);
    protected claimsControl = this.form.get(UserKeys.Claims) as UfControlGroup | undefined;

    close() {
        this.runtime.close();
    }

    protected addUser() {
        this.form.setSubmitted();

        if (this.form.invalid) {
            return;
        }

        this.runtime.close(this.form);
    }

}
